import React from "react";

const About = () => {
  return (
    <div>
      <div id="about" className="about">
        <div className="about-heading">
          <span>Who we are</span>
        </div>
        <div className="about-description">
          We are a passionate team of industry professionals dedicated to
          empowering aspiring developers and designers through comprehensive
          courses in Node.js, UX/UI Designing, MERN Stack, MEAN Stack, Java
          Stack, Angular, React.js, Web Development, Python Full Stack, and
          more.
        </div>
      </div>
    </div>
  );
};

export default About;
