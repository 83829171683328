import React from "react";
import { Link as ScrollLink } from "react-scroll";

const Trainings = () => {
  return (
    <div>
      <div id="trainings" className="methodology">
        <div className="methodology-heading">
          <span>Our trainings and methodology is simple!</span>
        </div>
        <div className="methodology-description">
          Take one skill and spend an hour daily in live classes. Of course you
          are saving money by not going to strange places to learn the same.
        </div>
        <div className="methodology-button">
          <ScrollLink to="feedback" smooth={true} duration={600}>
            What our students have to say
          </ScrollLink>
        </div>
      </div>
    </div>
  );
};

export default Trainings;
