import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import Courses from "./Courses";
import Trainings from "./Trainings";
import About from "./About";
import Register from "./Register";
import Footer from "./Footer";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <div className="home chessboard-background">
        <nav>
          <span className="nav-logo-container-1">
            zuClix <span className="sub-heading">Software Trainings</span>
          </span>
          <div
            className={`navbar-links-container ${
              isOpen ? "home chessboard-background open" : ""
            }`}
          >
            {location.pathname === "/" ? (
              <>
                <ScrollLink
                  to="courses"
                  smooth={true}
                  duration={500}
                  onClick={closeNavbar}
                >
                  Trainings
                </ScrollLink>
                <ScrollLink
                  to="about"
                  smooth={true}
                  duration={800}
                  onClick={closeNavbar}
                >
                  Who we are
                </ScrollLink>
                <ScrollLink
                  to="blog"
                  smooth={true}
                  duration={500}
                  onClick={closeNavbar}
                >
                  Blog
                </ScrollLink>
                <ScrollLink
                  to="register"
                  smooth={true}
                  duration={1000}
                  onClick={closeNavbar}
                >
                  Register for a training
                </ScrollLink>
              </>
            ) : (
              <>
                <HashLink to="/#courses" smooth onClick={closeNavbar}>
                  Trainings
                </HashLink>
                <HashLink to="/#about" smooth onClick={closeNavbar}>
                  Who we are
                </HashLink>
                <HashLink to="/#blog" smooth onClick={closeNavbar}>
                  Blog
                </HashLink>
                <ScrollLink
                  to="register"
                  smooth={true}
                  duration={1000}
                  onClick={closeNavbar}
                >
                  Register for a training
                </ScrollLink>
              </>
            )}
          </div>
          <div className="navbar-toggle" onClick={toggleNavbar}>
            {isOpen ? "✖" : "☰"}
          </div>
        </nav>
        <div className={`content ${isOpen ? "hide" : ""}`}>
          <div className="welcome-message">
            <div className="head-line">
              Expert-Led Online Trainings for Aspiring Developers & Designers.
            </div>
            <span className="sub-head">
              Learn from your comforts & we promise a joyful learning.
            </span>
            <div className="welcome-message-buttons">
              <ScrollLink to="courses" smooth={true} duration={500}>
                <div className="btn-outline-1">What we offer</div>
              </ScrollLink>
              <ScrollLink to="register" smooth={true} duration={800}>
                <div className="btn-outline-2">Register Now</div>
              </ScrollLink>
            </div>
          </div>
          <div className="cards-size">
            <div className="cards-first-set">
              <div className="card-1">
                <div className="pin1"></div>
                <div className="pin2"></div>Learn Skills
              </div>
              <div className="card-2">
                <div className="pin1"></div>
                <div className="pin2"></div>Build Knowledge
              </div>
            </div>
            <div className="cards-second-set">
              <div className="card-1">
                <div className="pin1"></div>
                <div className="pin2"></div>Grow Abilities
              </div>
              <div className="card-2">
                <div className="pin1"></div>
                <div className="pin2"></div>Succeed Together
              </div>
            </div>
          </div>
        </div>
      </div>
      <Trainings />
      <Courses />
      <About />
      <Register />
      <Footer />
    </div>
  );
};

export default Home;
