import React, { useState } from "react";
import Constants from "../config/Constants";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Courses = () => {
  const courses = Constants.constants.courseData;
  const [filter, setFilter] = useState("");

  const feedbackSettings = {
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
        },
      },
    ],
  };

  const courseSliderSettings = {
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    centerMode: true,
    rows: 2,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          centerMode: false,
          rows: 1,
        },
      },
    ],
  };

  const filteredCourses = courses.filter((course) => {
    return filter.length === 0 || course.type === filter;
  });

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  return (
    <div>
      <div id="courses" className="courses">
        <div className="courses-top-line">
          <div className="courses-heading">
            Explore our industry ready online <s>courses</s> trainings designed
            for you
          </div>
          <div className="filter-size">
            <div className="select-wrapper">
              <select
                name="filter"
                className="course-filter"
                value={filter}
                onChange={handleFilterChange}
              >
                <option key={1} value="">
                  All Trainings
                </option>
                <option key={2} value="No-Code">
                  No Coding
                </option>
                <option key={3} value="Front-end">
                  Front-end
                </option>
                <option key={4} value="Back-end">
                  Back-end
                </option>
                <option key={5} value="Full Stack">
                  Full Stack
                </option>
              </select>
              <div className="course-count">
                {filter.length ? filter : "All Trainings"}:{" "}
                {filteredCourses.length}
              </div>
            </div>
          </div>
        </div>
        <div className="grid-container">
          <Slider {...courseSliderSettings}>
            {filteredCourses.map((item, index) => (
              <Link to={`/course/${item.id}`} key={index} className="grid-item">
                <div className="course-image-border">
                  <img src={item.image} alt=""></img>
                </div>
                <div className="course-details">
                  <div className="course-name">{item.name}</div>
                  <div className="course-duration">
                    <div>
                      Training Duration : <span>{item.duration} Days</span>
                    </div>
                    <div className="course-mode">
                      Mode: <span>Online</span>
                    </div>
                  </div>
                  <div className="course-cost">
                    <div>
                      ₹ <span>{item.cost}</span>
                    </div>
                    <button>Details</button>
                  </div>
                </div>
              </Link>
            ))}
          </Slider>
        </div>
        <div className="pagination">
          <div className="courses-bottom">
            We charge less to encourage learning
          </div>
        </div>
      </div>
      <div className="assurance">
        <img src={`${Constants.imageUrl}victory.svg`} alt=""></img>We assure
        that you will be industry ready...
      </div>
      <div id="feedback" className="feedback">
        <div className="feedback-heading">What our students have to say</div>
        <Slider {...feedbackSettings} className="feedback-comments">
          <div className="feedback-slider">
            <div className="feedback-cards">
              <img src={`${Constants.imageUrl}vectorSymbol.svg`} alt=""></img>
              <div className="feedback-description">
                The UX/UI Designing course was amazing !{" "}
                <span>The hands-on projects really helped me</span> grasp the
                concepts. Highly recommend !
              </div>
              <div className="feedback-footer">
                <span>Ramesh Dev</span>
              </div>
              <label>Student at Aditya Institute</label>
            </div>
          </div>
          <div className="feedback-slider">
            <div className="feedback-cards">
              <img src={`${Constants.imageUrl}vectorSymbol.svg`} alt=""></img>
              <div className="feedback-description">
                Learning the MERN Stack here was{" "}
                <span> game-changer for my career.</span> The instructors were
                knowledgeable and supportive.
              </div>
              <div className="feedback-footer">
                <span>Anil Krishna</span>
              </div>
              <label>Working at L&T Infotech</label>
            </div>
          </div>
          <div className="feedback-slider">
            <div className="feedback-cards">
              <img src={`${Constants.imageUrl}vectorSymbol.svg`} alt=""></img>
              <div className="feedback-description">
                Java Stack training was thorough and practical.{" "}
                <span>I landed a job shortly after completing the course.</span>{" "}
                Thank you !
              </div>
              <div className="feedback-footer">
                <span>Rohith Kumar</span>
              </div>
              <label>Working at TCS</label>
            </div>
          </div>
          <div className="feedback-slider">
            <div className="feedback-cards">
              <img src={`${Constants.imageUrl}vectorSymbol.svg`} alt=""></img>
              <div className="feedback-description">
                React.js classes were very{" "}
                <span>engaging and informative.</span> The projects helped me
                build a solid portfolio.
              </div>
              <div className="feedback-footer">
                <span>Akash A</span>
              </div>
              <label>Working at Infosys</label>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default Courses;
