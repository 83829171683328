import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Constants from "../config/Constants";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const CoursePreview = () => {
  const { id } = useParams();
  const courseData = Constants.constants.courseOverView.find(
    (course) => course.id === id
  );
  const trainingCourse = Constants.constants.courseData.find(
    (course) => course.id === id
  );
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [courseTraining, setCourseTraining] = useState(trainingCourse.name);
  const [startDate, setStartDate] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const middleIndex = Math.ceil(courseData.achievements.length / 2);
  const leftColumnAchievements = courseData.achievements.slice(0, middleIndex);
  const rightColumnAchievements = courseData.achievements.slice(middleIndex);

  const careerMiddleIndex = Math.ceil(courseData.career.length / 2);
  const careerLeftColumnAchievements = courseData.career.slice(
    0,
    careerMiddleIndex
  );
  const careerRightColumnAchievements =
    courseData.career.slice(careerMiddleIndex);
  const [allExpanded, setAllExpanded] = useState(false);
  const [visibleSections, setVisibleSections] = useState(
    Array(courseData.mainContent.length).fill(false)
  );
  const toggleSection = (index) => {
    setVisibleSections((prevVisibleSections) => {
      const newVisibleSections = [...prevVisibleSections];
      newVisibleSections[index] = !newVisibleSections[index];
      return newVisibleSections;
    });
  };
  const toggleExpandCollapseAll = () => {
    const newVisibility = !allExpanded;
    setVisibleSections(
      Array(courseData.mainContent.length).fill(newVisibility)
    );
    setAllExpanded(newVisibility);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setSubmitError(null);

    const formData = new FormData();
    formData.append("Full_Name", fullName);
    formData.append("Phone_Number", phoneNumber);
    formData.append("Course_Training", courseTraining);
    formData.append("How_Soon_You_Can_Start", startDate);

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbwtDPJlg89ZBNwdGE-cM1d93dbnk2yQbVtJWiwLDmS3qK6kQDDpW0OLXA_ncFjmzVY-/exec",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit form");
      }

      setFullName("");
      setPhoneNumber("");
      setCourseTraining("");
      setStartDate("");
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmitError("Failed to submit form. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const dateInput = document.getElementById("startDate");
    if (!startDate) {
      dateInput.classList.add("placeholder");
      dateInput.setAttribute("data-placeholder", "dd/mm/yyyy");
    } else {
      dateInput.classList.remove("placeholder");
    }
  }, [startDate]);

  return (
    <div>
      <div className="home chessboard-background">
        <Navbar />
        <div className="coursePreview">
          <div className="course-welcome-message">
            <div className="course-head-line">{courseData.name}</div>
            <span className="course-sub-head">{courseData.description}</span>
            <div className="course-duration-preview">
              <div>
                Training Duration : <span>{courseData.duration} Days</span>
              </div>
              <div className="line">|</div>
              <div>
                Daily: <span>1 hour Online Class</span>
              </div>
              <div className="line">|</div>
              <div>
                Language: <span>Telugu & English</span>
              </div>
            </div>
            <div className="course-duration-preview-list">
              <li>
                Training Duration : <span>{courseData.duration} Days</span>
              </li>
              <li>
                Daily: <span>1 hour Online Class</span>
              </li>
              <li>
                Language: <span>Telugu & English</span>
              </li>
            </div>
            <div className="course-cost-preview">
              <div>
                ₹ <span>{courseData.cost}</span>
              </div>
            </div>
            <ScrollLink to="register" smooth={true} duration={800}>
              <div className="btn-outline-1">Register for this training</div>
            </ScrollLink>
          </div>
          <div>
            <img src={courseData.image} alt=""></img>
          </div>
        </div>
      </div>
      <div className="achive">
        <div className="achive-heading">
          <span>What you will achieve</span>
        </div>
        <div className="achievements-columns">
          <div className="achievements-column left-column">
            <ul>
              {leftColumnAchievements.map((achievement, index) => (
                <li className="achive-bullets" key={index}>
                  {achievement}
                </li>
              ))}
            </ul>
          </div>
          <div className="achievements-column right-column">
            <ul>
              {rightColumnAchievements.map((achievement, index) => (
                <li className="achive-bullets" key={index}>
                  {achievement}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="course-overview">
        <div className="course-overview-heading">
          Course Content
          <div className="expand-button">
            <button onClick={toggleExpandCollapseAll}>
              {allExpanded ? "Collapse All" : "Expand All"}
            </button>
          </div>
        </div>
        <div className="course-content">
          {courseData.mainContent.map((content, index) => (
            <div key={index} className="content-section">
              <button
                className="content-headline"
                onClick={() => toggleSection(index)}
              >
                <div className="collapse-module">
                  <span className="collapse-symbol">
                    {visibleSections[index] ? "▼" : "▶"}{" "}
                  </span>
                  <span className="collapse-headline">{content.headline}</span>
                </div>
                <span className="topics">
                  {content.subContent.reduce(
                    (acc, sub) => acc + sub.content.length,
                    0
                  )}{" "}
                  Topics
                </span>
              </button>
              {visibleSections[index] && (
                <div className="sub-content">
                  {content.subContent.map((sub, subIndex) => (
                    <div className="collapse" key={subIndex}>
                      <div className="course-sub-heading">{sub.heading}:</div>
                      <ul className="sub-list">
                        {sub.content.map((item, itemIndex) => (
                          <li key={itemIndex} className="sub-item">
                            {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="achive">
        <div className="achive-heading">
          <span>Career Prospects with {courseData.name}</span>
        </div>
        <div className="achievements-columns">
          <div className="achievements-column left-column">
            <ul>
              {careerLeftColumnAchievements.map((achievement, index) => (
                <li className="achive-bullets" key={index}>
                  {achievement}
                </li>
              ))}
            </ul>
          </div>
          <div className="achievements-column right-column">
            <ul>
              {careerRightColumnAchievements.map((achievement, index) => (
                <li className="achive-bullets" key={index}>
                  {achievement}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="register">
        <div className="register-heading">
          Enroll now to learn a skill, Our team will get back to you
        </div>
        <div className="enroll-cards">
          <div className="contact">
            <div className="contact-heading">Contact us</div>
            <div className="contact-form-group">
              <div className="contact-icon" id="mail">
                <a href="mailto:zuclix.digital@gmail.com">
                  <img src={`${Constants.imageUrl}mail.svg`} alt="" />
                </a>
              </div>
              <div className="contact-details">
                <label>Email Address:</label>
                <a href="mailto:zuclix.digital@gmail.com">
                  <span>zuclix.digital@gmail.com</span>
                </a>
              </div>
            </div>
            <div className="contact-form-group">
              <div className="contact-icon" id="call">
                <a href="tel:+918341875702">
                  <img src={`${Constants.imageUrl}call.svg`} alt="" />
                </a>
              </div>
              <div className="contact-details">
                <label>Phone Number:</label>
                <a href="tel:+918341875702">
                  <span>+91 83418 75702</span>
                </a>{" "}
                or{" "}
                <a href="tel:+919703623094">
                  <span>+91 97036 23094</span>
                </a>
              </div>
            </div>
            <div className="contact-form-group">
              <div className="contact-icon" id="location">
                <img src={`${Constants.imageUrl}location.svg`} alt="" />
              </div>
              <div className="contact-details">
                <label>Address:</label>
                <span>
                  Street No:8, Sairam Colony, Kommadi, Madhurawada,
                  Visakhapatnam, AP - 530048
                </span>
              </div>
            </div>
            <div className="contact-form-group">
              <iframe
                title="zuClix Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3669.6167799678205!2d83.34883577502073!3d17.829175583135317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a395be9d36ff9a3%3A0x3650a155ca875991!2szuClix%20Software%20Trainings!5e1!3m2!1sen!2sin!4v1719854627653!5m2!1sen!2sin"
                width="475"
                height="198"
                loading="lazy"
              ></iframe>
            </div>
          </div>
          <div id="register" className="register-form">
            <div className="register-form-heading">Register for training</div>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="form-group">
                <label htmlFor="fullName">Full Name</label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  placeholder="Enter your full name"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phoneNumber">Phone Number</label>
                <input
                  type="number"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Enter phone number"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="courseTraining">Training</label>
                <div className="select-wrapper">
                  <select
                    id="courseTraining"
                    name="courseTraining"
                    value={courseTraining}
                    onChange={(e) => setCourseTraining(e.target.value)}
                    required
                  >
                    <option key={""} value="" disabled>
                      Select training
                    </option>
                    {Constants.constants.courseData.map((course, index) => (
                      <option key={index} value={course.name}>
                        {course.name}
                      </option>
                    ))}
                    <option key={17} value="Graphic Designing">
                      Graphic Designing
                    </option>
                    <option key={18} value="Data Analyst">
                      Data Analyst
                    </option>
                    <option key={19} value="Java Frameworks">
                      Java Frameworks
                    </option>
                    <option key={20} value="Software Testing">
                      Software Testing
                    </option>
                    <option key={21} value="DevOps">
                      DevOps
                    </option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="startDate">How soon you can start?</label>
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="register-btn"
                  disabled={submitting}
                >
                  {submitting ? "Submitting..." : "Register"}
                </button>
                {submitError && <p className="error-message">{submitError}</p>}
              </div>
              {submitting ? (
                <div className="submit-message">
                  Form Submitted Successfully
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CoursePreview;
