import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { HashLink } from "react-router-hash-link";
import Constants from "../config/Constants";

const Footer = () => {
  return (
    <div className="footer">
      <nav className="footer-nav">
        <span className="nav-logo-container-1">
          zuClix <span className="sub-heading">Software Trainings</span>
        </span>
        <div className="navbar-links-container-footer">
          <HashLink to="/#courses" smooth>
            Trainings
          </HashLink>
          <HashLink to="/#about" smooth>
            Who we are
          </HashLink>
          <HashLink to="/#" smooth>
            Blog
          </HashLink>
          <ScrollLink to="register" smooth={true} duration={1000}>
            Register for a training
          </ScrollLink>
        </div>
      </nav>
      <div className="footer-bottom">
        <div className="copyright">
          Copyright © 2024 by zuClix Smart Learning
          <ScrollLink to="root" smooth={true} duration={600}>
            <img
              className="far-top"
              src={`${Constants.imageUrl}top-up.svg`}
              alt=""
            />
          </ScrollLink>
        </div>
        <div className="social-icons">
          <a href="/">
            <img src={`${Constants.imageUrl}instagram.svg`} alt="" />
          </a>
          <a href="/">
            <img src={`${Constants.imageUrl}linkedIn.svg`} alt="" />
          </a>
          <a href="/">
            <img src={`${Constants.imageUrl}facebook.svg`} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
