import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false);
  };

  return (
    <nav>
      <span className="nav-logo-container-1">
        zuClix <span className="sub-heading">Software Trainings</span>
      </span>
      <div
        className={`navbar-links-container ${
          isOpen ? "home chessboard-background open" : ""
        }`}
      >
        {location.pathname === "/" ? (
          <>
            <ScrollLink
              to="courses"
              smooth={true}
              duration={500}
              onClick={closeNavbar}
            >
              Trainings
            </ScrollLink>
            <ScrollLink
              to="about"
              smooth={true}
              duration={800}
              onClick={closeNavbar}
            >
              Who we are
            </ScrollLink>
            <ScrollLink
              to="blog"
              smooth={true}
              duration={500}
              onClick={closeNavbar}
            >
              Blog
            </ScrollLink>
            <ScrollLink
              to="register"
              smooth={true}
              duration={1000}
              onClick={closeNavbar}
            >
              Register for a training
            </ScrollLink>
          </>
        ) : (
          <>
            <HashLink to="/#courses" smooth onClick={closeNavbar}>
              Trainings
            </HashLink>
            <HashLink to="/#about" smooth onClick={closeNavbar}>
              Who we are
            </HashLink>
            <HashLink to="/#blog" smooth onClick={closeNavbar}>
              Blog
            </HashLink>
            <ScrollLink
              to="register"
              smooth={true}
              duration={1000}
              onClick={closeNavbar}
            >
              Register for a training
            </ScrollLink>
          </>
        )}
      </div>
      <div className="navbar-toggle" onClick={toggleNavbar}>
        {isOpen ? "✖" : "☰"}
      </div>
    </nav>
  );
};

export default Navbar;
