const constants = {};

constants.imageUrl = "/assets/images/";

constants.constants = {
  courseData: [
    {
      id: "node",
      name: "Node.js Development",
      duration: "60",
      image: `${constants.imageUrl}node.png`,
      cost: "6,499",
      type: "Back-end",
    },
    {
      id: "ui-ux",
      name: "UX/UI Designing",
      duration: "60",
      image: `${constants.imageUrl}ui_ux.png`,
      cost: "5,999",
      type: "No-Code",
    },
    {
      id: "sales",
      name: "Salesforce",
      duration: "120",
      image: `${constants.imageUrl}sales-full-stack.png`,
      cost: "12,999",
      type: "No-Code",
    },
    {
      id: "vlocityandlwc",
      name: "Salesforce Vlocity plus LWC",
      duration: "60",
      image: `${constants.imageUrl}sales-lwc.png`,
      cost: "6,499",
      type: "No-Code",
    },
    {
      id: "salesforce-admin-vlocity",
      name: "Salesforce Admin plus Vlocity",
      duration: "60",
      image: `${constants.imageUrl}sales-admin-velocity.png`,
      cost: "6,499",
      type: "No-Code",
    },
    {
      id: "sales-admin-development",
      name: "Salesforce Admin plus Development",
      duration: "60",
      image: `${constants.imageUrl}sales-admin-dev.png`,
      cost: "6,499",
      type: "No-Code",
    },
    {
      id: "digital-marketing",
      name: "Digital Marketing Fundamentals",
      duration: "60",
      image: `${constants.imageUrl}digital_marketing.png`,
      cost: "6,499",
      type: "No-Code",
    },
    {
      id: "web-development",
      name: "Web Development",
      duration: "60",
      image: `${constants.imageUrl}web_development.png`,
      cost: "6,499",
      type: "Front-end",
    },
    {
      id: "react",
      name: "React.js",
      duration: "60",
      image: `${constants.imageUrl}react.png`,
      cost: "6,499",
      type: "Front-end",
    },
    {
      id: "angular",
      name: "Angular",
      duration: "60",
      image: `${constants.imageUrl}angular.png`,
      cost: "6,499",
      type: "Front-end",
    },
    {
      id: "core-advanced-java",
      name: "Core and Advanced Java",
      duration: "60",
      image: `${constants.imageUrl}core_advanced-java.png`,
      cost: "6,499",
      type: "Back-end",
    },
    {
      id: "python",
      name: "Core and Advanced Python",
      duration: "60",
      image: `${constants.imageUrl}core_advanced_python.png`,
      cost: "6,499",
      type: "Back-end",
    },
    {
      id: "java-full-stack",
      name: "Java Fullstack",
      duration: "120",
      image: `${constants.imageUrl}java-full-stack.png`,
      cost: "12,999",
      type: "Full Stack",
    },
    {
      id: "python-full-stack",
      name: "Python Fullstack",
      duration: "120",
      image: `${constants.imageUrl}python-full-stack.png`,
      cost: "12,999",
      type: "Full Stack",
    },
    {
      id: "mern-stack",
      name: "MERN Fullstack",
      duration: "120",
      image: `${constants.imageUrl}mern-full-stack.png`,
      cost: "12,999",
      type: "Full Stack",
    },
    {
      id: "mean-stack",
      name: "MEAN Fullstack",
      duration: "120",
      image: `${constants.imageUrl}mean-full-stack.png`,
      cost: "12,999",
      type: "Full Stack",
    },
  ],
  courseOverView: [
    {
      id: "node",
      name: "Node.js Development",
      description:
        "Explore Node.js for server-side JavaScript development and real-time applications.",
      duration: "60",
      image: `${constants.imageUrl}node_course.png`,
      cost: "6,499",
      achievements: [
        "Node.js fundamentals and event loop",
        "Express.js for server-side development",
        "Routing and middleware concepts",
        "REST API creation",
        "Database integration with MongoDB",
        "Authentication and authorization systems",
        "Real-time communication with sockets",
        "File uploading and streaming",
        "Performance tuning and scaling",
        "Deployment on cloud platforms",
      ],
      mainContent: [
        {
          headline: "Introduction to Node.js",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "What is Node.js?",
                "Setting up the environment",
                "Node.js architecture and event loop",
                "Running the first Node.js application",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Install Node.js and npm",
                "Write and execute a simple 'Hello, World!' application",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Official Node.js documentation",
                "Basic JavaScript refresher",
              ],
            },
          ],
        },
        {
          headline: "Core Modules and NPM",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Understanding and using core modules (fs, path, http, etc.)",
                "Introduction to npm (Node Package Manager)",
                "Managing packages and dependencies",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create a project using npm",
                "Use core modules to perform file operations",
              ],
            },
            {
              heading: "Resources",
              content: ["npm documentation", "Tutorials on core modules"],
            },
          ],
        },
        {
          headline: "Building a Web Server",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Creating a simple web server using the http module",
                "Handling requests and responses",
                "Basic routing",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Build a basic web server",
                "Implement different routes",
              ],
            },
            {
              heading: "Resources",
              content: ["Node.js documentation on the http module"],
            },
          ],
        },
        {
          headline: "Express.js Framework",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Introduction to Express.js",
                "Setting up an Express.js project",
                "Middleware and routing",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Build a basic web application using Express.js",
                "Implement middleware for logging and error handling",
              ],
            },
            {
              heading: "Resources",
              content: ["Express.js documentation", "Tutorials on Express.js"],
            },
          ],
        },
        {
          headline: "Working with Databases",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Introduction to databases (SQL and NoSQL)",
                "Connecting to a database (MongoDB or PostgreSQL)",
                "CRUD operations",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Set up a database",
                "Connect your Node.js application to the database",
                "Implement CRUD operations",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Mongoose (for MongoDB)",
                "Sequelize (for SQL databases)",
              ],
            },
          ],
        },
        {
          headline: "Authentication and Authorization",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "User authentication concepts",
                "Implementing authentication using jwt",
                "Role-based access control",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Add user authentication to your application",
                "Implement protected routes",
              ],
            },
            {
              heading: "Resources",
              content: [
                "jwt documentation",
                "Tutorials on authentication and authorization",
              ],
            },
          ],
        },
        {
          headline: "Deployment and Best Practices",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Preparing an application for production",
                "Deployment strategies (Heroku, AWS, etc.)",
                "Best practices in Node.js development",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Deploy a Node.js application",
                "Optimize the application for production",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Deployment documentation for Heroku, AWS",
                "Optimization guides",
              ],
            },
          ],
        },
      ],
      career: [
        "Node.js Developer",
        "Backend Developer",
        "MongoDB Developer",
        "JavaScript Developer",
        "MERN Stack Developer (Learn React.js)",
      ],
    },
    {
      id: "digital-marketing",
      name: "Digital Marketing Fundamentals",
      description:
        "Comprehensive training in digital marketing covering core concepts and practical applications.",
      duration: "60",
      image: `${constants.imageUrl}digital-marketing-course.png`,
      cost: "6,499",
      achievements: [
        "Understanding digital marketing strategies",
        "SEO fundamentals and best practices",
        "PPC advertising and campaign management",
        "Social media marketing strategies",
        "Email marketing techniques",
        "Content marketing essentials",
        "Analytics and reporting in digital marketing",
        "Conversion rate optimization (CRO)",
        "Marketing automation tools and practices",
        "Career guidance and portfolio development",
      ],
      mainContent: [
        {
          headline: "Introduction to Digital Marketing",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "What is digital marketing?",
                "Digital marketing vs traditional marketing",
                "Understanding the digital marketing landscape",
                "Current trends and future directions",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Exploring digital marketing platforms",
                "Analyzing successful digital campaigns",
                "Setting up digital marketing tools",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Industry reports and case studies",
                "Digital marketing blogs and websites",
              ],
            },
          ],
        },
        {
          headline: "SEO and Website Optimization",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Introduction to search engines and SEO",
                "On-page and off-page SEO techniques",
                "Keyword research and analysis",
                "SEO tools and software",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Optimizing website content",
                "Implementing SEO best practices",
                "Monitoring and improving search engine rankings",
              ],
            },
            {
              heading: "Resources",
              content: ["SEO guides and tutorials", "SEO tool documentation"],
            },
          ],
        },
        {
          headline: "PPC Advertising and Campaign Management",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Introduction to PPC advertising",
                "Creating effective ad campaigns",
                "PPC bidding strategies",
                "Campaign optimization",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Setting up PPC campaigns",
                "Analyzing PPC performance metrics",
                "Adjusting ad budgets and targeting",
              ],
            },
            {
              heading: "Resources",
              content: [
                "PPC platforms documentation",
                "Case studies on successful campaigns",
              ],
            },
          ],
        },
        {
          headline: "Social Media Marketing Strategies",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Introduction to social media marketing",
                "Creating a social media strategy",
                "Content planning and scheduling",
                "Engagement and community management",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Setting up social media profiles",
                "Creating and curating content",
                "Measuring social media ROI",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Social media marketing guides",
                "Tools for social media management",
              ],
            },
          ],
        },
        {
          headline: "Email Marketing Techniques",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Introduction to email marketing",
                "Building an email list",
                "Designing effective email campaigns",
                "Email automation and personalization",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Creating email templates",
                "Setting up email automation sequences",
                "Analyzing email marketing metrics",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Email marketing platforms documentation",
                "Best practices for email marketing",
              ],
            },
          ],
        },
        {
          headline: "Content Marketing Essentials",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Understanding content marketing",
                "Creating a content strategy",
                "Content ideation and creation",
                "Content distribution and promotion",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Developing content calendars",
                "Writing and editing blog posts",
                "Promoting content on social channels",
              ],
            },
            {
              heading: "Resources",
              content: ["Content marketing guides", "Content creation tools"],
            },
          ],
        },
        {
          headline: "Analytics and Reporting in Digital Marketing",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Introduction to digital marketing analytics",
                "Setting up analytics tools",
                "Interpreting marketing data",
                "Creating performance reports",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Setting up Google Analytics",
                "Analyzing website traffic",
                "Measuring campaign effectiveness",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Analytics platforms documentation",
                "Data analysis tutorials",
              ],
            },
          ],
        },
        {
          headline: "Conversion Rate Optimization (CRO)",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Introduction to conversion optimization",
                "Identifying conversion goals",
                "A/B testing and experimentation",
                "Optimizing landing pages",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Conducting conversion audits",
                "Implementing CRO strategies",
                "Measuring and improving conversion rates",
              ],
            },
            {
              heading: "Resources",
              content: [
                "CRO guides and case studies",
                "Tools for CRO analysis",
              ],
            },
          ],
        },
        {
          headline: "Marketing Automation Tools and Practices",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Introduction to marketing automation",
                "Choosing the right automation tools",
                "Setting up automated workflows",
                "Personalization and segmentation",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Implementing email automation",
                "Creating automated campaigns",
                "Analyzing automation performance",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Marketing automation platforms documentation",
                "Automation best practices",
              ],
            },
          ],
        },
        {
          headline: "Career Guidance and Portfolio Development",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Building a career in digital marketing",
                "Preparing a digital marketing portfolio",
                "Job search strategies",
                "Networking and professional development",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Creating a professional LinkedIn profile",
                "Showcasing digital marketing projects",
                "Practicing interview skills",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Career resources and job boards",
                "Interview preparation guides",
              ],
            },
          ],
        },
      ],
      career: [
        "Digital Marketing Specialist",
        "SEO Analyst",
        "PPC Specialist",
        "Social Media Manager",
        "Content Strategist",
        "Email Marketing Specialist",
        "Marketing Analyst",
      ],
    },
    {
      id: "angular",
      name: "Angular Development",
      description:
        "Master Angular for building dynamic single-page applications (SPAs).",
      duration: "60",
      image: `${constants.imageUrl}angular-course.png`,
      cost: "6,499",
      achievements: [
        "Angular fundamentals and architecture",
        "Component-based development",
        "Data binding and directives",
        "Services and dependency injection",
        "Routing and navigation",
        "State management with NgRx",
        "Testing Angular applications",
        "Deployment of Angular applications",
      ],
      mainContent: [
        {
          headline: "Introduction to Angular",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Angular architecture",
                "Setting up Angular CLI",
                "Creating your first Angular app",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Install Angular CLI",
                "Create and run a new Angular project",
              ],
            },
            {
              heading: "Resources",
              content: ["Angular documentation", "Angular CLI tutorial"],
            },
          ],
        },
        {
          headline: "Components and Templates",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Creating and using components",
                "Template syntax",
                "Data binding",
                "Built-in directives",
              ],
            },
            {
              heading: "Activities",
              content: ["Build reusable components", "Implement data binding"],
            },
            {
              heading: "Resources",
              content: [
                "Angular documentation (components)",
                "Angular tutorial on components",
              ],
            },
          ],
        },
        {
          headline: "Services and Dependency Injection",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Creating and using services",
                "Dependency injection",
                "Angular's hierarchical injector",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Build and inject services",
                "Manage application state with services",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Angular documentation (services)",
                "Tutorials on dependency injection",
              ],
            },
          ],
        },
        {
          headline: "Routing and Navigation",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Configuring routes",
                "Navigation and parameterized routes",
                "Route guards and lazy loading",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Implement routing in your app",
                "Secure routes with guards",
              ],
            },
            {
              heading: "Resources",
              content: ["Angular documentation (routing)", "Routing tutorials"],
            },
          ],
        },
        {
          headline: "State Management with NgRx",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to NgRx",
                "Setting up NgRx store",
                "Actions, reducers, and selectors",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Manage state with NgRx",
                "Implement stateful components",
              ],
            },
            {
              heading: "Resources",
              content: ["NgRx documentation", "Tutorials on state management"],
            },
          ],
        },
        {
          headline: "Testing Angular Applications",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Unit testing with Jasmine and Karma",
                "End-to-end testing with Protractor",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Write unit tests for components",
                "Perform E2E testing",
              ],
            },
            {
              heading: "Resources",
              content: ["Angular documentation (testing)", "Testing tutorials"],
            },
          ],
        },
        {
          headline: "Deployment",
          subContent: [
            {
              heading: "Topics",
              content: ["Building for production", "Deployment strategies"],
            },
            {
              heading: "Activities",
              content: ["Deploy an Angular app", "Optimize for performance"],
            },
            {
              heading: "Resources",
              content: [
                "Angular documentation (deployment)",
                "Deployment guides",
              ],
            },
          ],
        },
      ],
      career: [
        "Angular Developer",
        "Front-end Developer",
        "Full Stack Developer",
      ],
    },
    {
      id: "python",
      name: "Core and Advanced Python",
      description:
        "Master Python for scripting, web development, data analysis, and machine learning.",
      duration: "60",
      image: `${constants.imageUrl}core-advanced-python-course.png`,
      cost: "6,499",
      achievements: [
        "Python syntax and programming basics",
        "Object-oriented programming in Python",
        "Working with modules and packages",
        "File handling and exceptions",
        "Data manipulation with Pandas",
        "Web development with Django/Flask",
        "REST API creation",
        "Introduction to machine learning with scikit-learn",
        "Working with databases (SQL/NoSQL)",
        "Testing and debugging",
      ],
      mainContent: [
        {
          headline: "Introduction to Python",
          subContent: [
            {
              heading: "Topics",
              content: [
                "What is Python?",
                "Setting up the environment",
                "Python syntax and variables",
                "Basic data types and operations",
                "Control structures (if, for, while)",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Install Python and an IDE (e.g., PyCharm)",
                "Write and execute basic Python scripts",
                "Practice with loops and conditionals",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Official Python documentation",
                "Python tutorials on W3Schools",
              ],
            },
          ],
        },
        {
          headline: "Object-Oriented Programming",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Classes and objects",
                "Inheritance and polymorphism",
                "Encapsulation and abstraction",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create classes and instantiate objects",
                "Implement inheritance and method overriding",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Python OOP documentation",
                "Tutorials on object-oriented programming",
              ],
            },
          ],
        },
        {
          headline: "Modules and Packages",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Importing modules",
                "Creating and using packages",
                "Standard library overview (os, sys, math, etc.)",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Write scripts using standard library modules",
                "Organize code into packages",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Python standard library documentation",
                "Tutorials on modules and packages",
              ],
            },
          ],
        },
        {
          headline: "File Handling and Exceptions",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Reading and writing files",
                "Handling exceptions with try, except, finally",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Perform file operations (read, write, append)",
                "Implement exception handling in scripts",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Python documentation on file handling",
                "Tutorials on exception handling",
              ],
            },
          ],
        },
        {
          headline: "Data Manipulation with Pandas",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Pandas",
                "DataFrames and Series",
                "Data cleaning and manipulation",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Load and analyze datasets",
                "Perform data cleaning and transformations",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Pandas documentation",
                "Tutorials on data manipulation with Pandas",
              ],
            },
          ],
        },
        {
          headline: "Web Development with Django/Flask",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Django/Flask",
                "Setting up a project",
                "Creating models and views",
                "URL routing and templates",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Build a simple web application",
                "Implement CRUD operations with Django/Flask",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Django and Flask documentation",
                "Web development tutorials",
              ],
            },
          ],
        },
        {
          headline: "REST API Creation",
          subContent: [
            {
              heading: "Topics",
              content: [
                "RESTful principles",
                "Building APIs with Django REST framework/Flask-RESTful",
              ],
            },
            {
              heading: "Activities",
              content: ["Create RESTful endpoints", "Test APIs with Postman"],
            },
            {
              heading: "Resources",
              content: [
                "Django REST framework documentation",
                "Flask-RESTful documentation",
              ],
            },
          ],
        },
        {
          headline: "Introduction to Machine Learning with Scikit-learn",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Basics of machine learning",
                "Data preprocessing",
                "Building and evaluating models",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Implement simple ML models (e.g., linear regression)",
                "Evaluate model performance",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Scikit-learn documentation",
                "Tutorials on machine learning with Python",
              ],
            },
          ],
        },
        {
          headline: "Working with Databases",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Connecting to SQL/NoSQL databases",
                "Performing CRUD operations",
                "Using ORMs (Django ORM, SQLAlchemy)",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Set up a database connection",
                "Implement database operations in Python",
              ],
            },
            {
              heading: "Resources",
              content: [
                "SQLAlchemy and Django ORM documentation",
                "Database connectivity tutorials",
              ],
            },
          ],
        },
        {
          headline: "Testing and Debugging",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Writing unit tests with unittest/pytest",
                "Debugging techniques",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Write and run unit tests",
                "Debug Python code using IDE tools",
              ],
            },
            {
              heading: "Resources",
              content: [
                "unittest and pytest documentation",
                "Debugging tutorials",
              ],
            },
          ],
        },
      ],
      career: [
        "Python Developer",
        "Data Analyst",
        "Web Developer",
        "Machine Learning Engineer",
        "Full Stack Developer",
      ],
    },
    {
      id: "mern-stack",
      name: "MERN Stack Development",
      description:
        "Master the MERN stack (MongoDB, Express.js, React.js, Node.js) for full-stack JavaScript development.",
      duration: "120",
      image: `${constants.imageUrl}mern-stack-course.png`,
      cost: "12,999",
      achievements: [
        "Fundamentals of each technology in the MERN stack",
        "Building RESTful APIs with Express.js",
        "Developing dynamic UIs with React.js",
        "Database operations with MongoDB",
        "Integrating front-end and back-end",
        "Authentication and authorization",
        "Real-time applications with WebSockets",
        "Deployment on cloud platforms",
      ],
      mainContent: [
        {
          headline: "Introduction to MERN Stack",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Overview of MongoDB, Express.js, React.js, Node.js",
                "Setting up the development environment",
                "Understanding the architecture",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Install Node.js, MongoDB, and create a React app",
                "Set up a basic Express server",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Official documentation for MongoDB, Express.js, React.js, Node.js",
                "Tutorials on setting up the MERN stack",
              ],
            },
          ],
        },
        {
          headline: "MongoDB",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to NoSQL databases",
                "Setting up MongoDB",
                "CRUD operations with MongoDB",
                "Mongoose for MongoDB",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create and connect to a MongoDB database",
                "Perform CRUD operations using Mongoose",
              ],
            },
            {
              heading: "Resources",
              content: ["MongoDB documentation", "Mongoose documentation"],
            },
          ],
        },
        {
          headline: "Express.js",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Express.js",
                "Setting up an Express.js project",
                "Middleware and routing",
                "Building RESTful APIs",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create a basic web application with Express.js",
                "Implement RESTful endpoints",
              ],
            },
            {
              heading: "Resources",
              content: ["Express.js documentation", "Tutorials on Express.js"],
            },
          ],
        },
        {
          headline: "React.js",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to React.js",
                "Component-based architecture",
                "State and props management",
                "Hooks in React.js",
                "Routing with React Router",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Build a dynamic UI with React.js",
                "Implement routing in React.js",
              ],
            },
            {
              heading: "Resources",
              content: ["React.js documentation", "React Router documentation"],
            },
          ],
        },
        {
          headline: "Integrating Front-end and Back-end",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Connecting React.js front-end with Express.js back-end",
                "Fetching data from APIs",
                "State management with Redux",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Integrate React.js and Express.js",
                "Manage state with Redux",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Tutorials on integrating front-end and back-end",
                "Redux documentation",
              ],
            },
          ],
        },
        {
          headline: "Authentication and Authorization",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Implementing user authentication",
                "JSON Web Tokens (JWT)",
                "Role-based access control",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Add authentication to the MERN application",
                "Protect routes with JWT",
              ],
            },
            {
              heading: "Resources",
              content: ["JWT documentation", "Authentication tutorials"],
            },
          ],
        },
        {
          headline: "Real-time Applications with WebSockets",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to WebSockets",
                "Setting up real-time communication with Socket.io",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Implement real-time features in the application",
                "Use Socket.io for real-time updates",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Socket.io documentation",
                "Real-time application tutorials",
              ],
            },
          ],
        },
        {
          headline: "Deployment",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Preparing the MERN application for production",
                "Deploying on platforms like Heroku, AWS",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Deploy the MERN application",
                "Optimize for performance",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Deployment documentation for Heroku, AWS",
                "Optimization guides",
              ],
            },
          ],
        },
      ],
      career: [
        "Full Stack Developer",
        "MERN Stack Developer",
        "Backend Developer",
        "Front-end Developer",
      ],
    },
    {
      id: "mean-stack",
      name: "MEAN Stack Development",
      description:
        "Master the MEAN stack (MongoDB, Express.js, Angular, Node.js) for full-stack JavaScript development.",
      duration: "120",
      image: `${constants.imageUrl}mean-stack-course.png`,
      cost: "12,999",
      achievements: [
        "Fundamentals of each technology in the MEAN stack",
        "Building RESTful APIs with Express.js",
        "Developing dynamic SPAs with Angular",
        "Database operations with MongoDB",
        "Integrating front-end and back-end",
        "Authentication and authorization",
        "Real-time applications with WebSockets",
        "Deployment on cloud platforms",
      ],
      mainContent: [
        {
          headline: "Introduction to MEAN Stack",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Overview of MongoDB, Express.js, Angular, Node.js",
                "Setting up the development environment",
                "Understanding the architecture",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Install Node.js, MongoDB, and Angular CLI",
                "Set up a basic Express server",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Official documentation for MongoDB, Express.js, Angular, Node.js",
                "Tutorials on setting up the MEAN stack",
              ],
            },
          ],
        },
        {
          headline: "MongoDB",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to NoSQL databases",
                "Setting up MongoDB",
                "CRUD operations with MongoDB",
                "Mongoose for MongoDB",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create and connect to a MongoDB database",
                "Perform CRUD operations using Mongoose",
              ],
            },
            {
              heading: "Resources",
              content: ["MongoDB documentation", "Mongoose documentation"],
            },
          ],
        },
        {
          headline: "Express.js",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Express.js",
                "Setting up an Express.js project",
                "Middleware and routing",
                "Building RESTful APIs",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create a basic web application with Express.js",
                "Implement RESTful endpoints",
              ],
            },
            {
              heading: "Resources",
              content: ["Express.js documentation", "Tutorials on Express.js"],
            },
          ],
        },
        {
          headline: "Angular",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Angular",
                "Setting up an Angular project",
                "Component-based architecture",
                "Data binding and directives",
                "Services and dependency injection",
                "Routing and navigation",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Build a dynamic SPA with Angular",
                "Implement routing in Angular",
              ],
            },
            {
              heading: "Resources",
              content: ["Angular documentation", "Angular CLI documentation"],
            },
          ],
        },
        {
          headline: "Integrating Front-end and Back-end",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Connecting Angular front-end with Express.js back-end",
                "Fetching data from APIs",
                "State management with NgRx",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Integrate Angular and Express.js",
                "Manage state with NgRx",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Tutorials on integrating front-end and back-end",
                "NgRx documentation",
              ],
            },
          ],
        },
        {
          headline: "Authentication and Authorization",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Implementing user authentication",
                "JSON Web Tokens (JWT)",
                "Role-based access control",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Add authentication to the MEAN application",
                "Protect routes with JWT",
              ],
            },
            {
              heading: "Resources",
              content: ["JWT documentation", "Authentication tutorials"],
            },
          ],
        },
        {
          headline: "Real-time Applications with WebSockets",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to WebSockets",
                "Setting up real-time communication with Socket.io",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Implement real-time features in the application",
                "Use Socket.io for real-time updates",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Socket.io documentation",
                "Real-time application tutorials",
              ],
            },
          ],
        },
        {
          headline: "Deployment",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Preparing the MEAN application for production",
                "Deploying on platforms like Heroku, AWS",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Deploy the MEAN application",
                "Optimize for performance",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Deployment documentation for Heroku, AWS",
                "Optimization guides",
              ],
            },
          ],
        },
      ],
      career: [
        "Full Stack Developer",
        "MEAN Stack Developer",
        "Backend Developer",
        "Front-end Developer",
      ],
    },
    {
      id: "python-full-stack",
      name: "Python Full Stack Development",
      description:
        "Master full-stack development using Python for back-end and Angular or React.js for front-end.",
      duration: "120",
      image: `${constants.imageUrl}python-full-stack-course.png`,
      cost: "12,999",
      achievements: [
        "Fundamentals of Python for back-end development",
        "Web development with Django/Flask",
        "Building RESTful APIs",
        "Front-end development with Angular or React.js",
        "Integrating front-end and back-end",
        "Authentication and authorization",
        "Real-time applications with WebSockets",
        "Deployment on cloud platforms",
      ],
      mainContent: [
        {
          headline: "Introduction to Python Full Stack",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Overview of full-stack development",
                "Setting up the development environment",
                "Understanding the architecture",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Install Python, Django/Flask, and Angular/React.js",
                "Set up a basic back-end project",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Official documentation for Python, Django, Flask, Angular, React.js",
                "Tutorials on setting up the full stack",
              ],
            },
          ],
        },
        {
          headline: "Python Back-end Development",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Django/Flask",
                "Setting up a Django/Flask project",
                "Models, views, and templates",
                "Building RESTful APIs with Django REST framework/Flask-RESTful",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create a basic web application with Django/Flask",
                "Implement RESTful endpoints",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Django and Flask documentation",
                "Django REST framework and Flask-RESTful documentation",
              ],
            },
          ],
        },
        {
          headline: "Angular/React.js Front-end Development",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Angular/React.js",
                "Setting up an Angular/React.js project",
                "Component-based architecture",
                "State and props management (React.js) / Data binding and directives (Angular)",
                "Routing and navigation",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Build a dynamic SPA with Angular/React.js",
                "Implement routing in Angular/React.js",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Angular and React.js documentation",
                "Angular CLI and React Router documentation",
              ],
            },
          ],
        },
        {
          headline: "Integrating Front-end and Back-end",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Connecting Angular/React.js front-end with Django/Flask back-end",
                "Fetching data from APIs",
                "State management with NgRx (Angular) / Redux (React.js)",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Integrate Angular/React.js and Django/Flask",
                "Manage state with NgRx/Redux",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Tutorials on integrating front-end and back-end",
                "NgRx and Redux documentation",
              ],
            },
          ],
        },
        {
          headline: "Authentication and Authorization",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Implementing user authentication",
                "JSON Web Tokens (JWT)",
                "Role-based access control",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Add authentication to the full stack application",
                "Protect routes with JWT",
              ],
            },
            {
              heading: "Resources",
              content: ["JWT documentation", "Authentication tutorials"],
            },
          ],
        },
        {
          headline: "Real-time Applications with WebSockets",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to WebSockets",
                "Setting up real-time communication with Socket.io",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Implement real-time features in the application",
                "Use Socket.io for real-time updates",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Socket.io documentation",
                "Real-time application tutorials",
              ],
            },
          ],
        },
        {
          headline: "Deployment",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Preparing the full stack application for production",
                "Deploying on platforms like Heroku, AWS",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Deploy the full stack application",
                "Optimize for performance",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Deployment documentation for Heroku, AWS",
                "Optimization guides",
              ],
            },
          ],
        },
      ],
      career: [
        "Full Stack Developer",
        "Python Developer",
        "Front-end Developer",
        "Backend Developer",
      ],
    },
    {
      id: "java-full-stack",
      name: "Java Full Stack Development",
      description:
        "Master full-stack development using Java for back-end and Angular or React.js for front-end.",
      duration: "120",
      image: `${constants.imageUrl}java-full-stack-course.png`,
      cost: "12,999",
      achievements: [
        "Fundamentals of Java for back-end development",
        "Web development with Spring Boot",
        "Building RESTful APIs",
        "Front-end development with Angular or React.js",
        "Integrating front-end and back-end",
        "Authentication and authorization",
        "Real-time applications with WebSockets",
        "Deployment on cloud platforms",
      ],
      mainContent: [
        {
          headline: "Introduction to Java Full Stack",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Overview of full-stack development",
                "Setting up the development environment",
                "Understanding the architecture",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Install Java, Spring Boot, and Angular/React.js",
                "Set up a basic back-end project",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Official documentation for Java, Spring Boot, Angular, React.js",
                "Tutorials on setting up the full stack",
              ],
            },
          ],
        },
        {
          headline: "Java Back-end Development",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Spring Boot",
                "Setting up a Spring Boot project",
                "Models, controllers, and services",
                "Building RESTful APIs with Spring Boot",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create a basic web application with Spring Boot",
                "Implement RESTful endpoints",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Spring Boot documentation",
                "Tutorials on Spring Boot",
              ],
            },
          ],
        },
        {
          headline: "Angular/React.js Front-end Development",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Angular/React.js",
                "Setting up an Angular/React.js project",
                "Component-based architecture",
                "State and props management (React.js) / Data binding and directives (Angular)",
                "Routing and navigation",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Build a dynamic SPA with Angular/React.js",
                "Implement routing in Angular/React.js",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Angular and React.js documentation",
                "Angular CLI and React Router documentation",
              ],
            },
          ],
        },
        {
          headline: "Integrating Front-end and Back-end",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Connecting Angular/React.js front-end with Spring Boot back-end",
                "Fetching data from APIs",
                "State management with NgRx (Angular) / Redux (React.js)",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Integrate Angular/React.js and Spring Boot",
                "Manage state with NgRx/Redux",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Tutorials on integrating front-end and back-end",
                "NgRx and Redux documentation",
              ],
            },
          ],
        },
        {
          headline: "Authentication and Authorization",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Implementing user authentication",
                "JSON Web Tokens (JWT)",
                "Role-based access control",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Add authentication to the full stack application",
                "Protect routes with JWT",
              ],
            },
            {
              heading: "Resources",
              content: ["JWT documentation", "Authentication tutorials"],
            },
          ],
        },
        {
          headline: "Real-time Applications with WebSockets",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to WebSockets",
                "Setting up real-time communication with Socket.io",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Implement real-time features in the application",
                "Use Socket.io for real-time updates",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Socket.io documentation",
                "Real-time application tutorials",
              ],
            },
          ],
        },
        {
          headline: "Deployment",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Preparing the full stack application for production",
                "Deploying on platforms like Heroku, AWS",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Deploy the full stack application",
                "Optimize for performance",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Deployment documentation for Heroku, AWS",
                "Optimization guides",
              ],
            },
          ],
        },
      ],
      career: [
        "Full Stack Developer",
        "Java Developer",
        "Front-end Developer",
        "Backend Developer",
      ],
    },
    {
      id: "web-development",
      name: "Web Development",
      description:
        "Build a strong foundation in web development, covering both front-end and back-end technologies.",
      duration: "60",
      image: `${constants.imageUrl}web-development-course.png`,
      cost: "6,499",
      achievements: [
        "HTML, CSS, and JavaScript fundamentals",
        "Responsive design with Bootstrap",
        "Version control with Git",
        "Basic web server setup",
        "Simple REST API creation",
        "Database integration (SQL/NoSQL)",
        "Deployment on cloud platforms",
      ],
      mainContent: [
        {
          headline: "HTML & CSS",
          subContent: [
            {
              heading: "Topics",
              content: [
                "HTML syntax and structure",
                "Styling with CSS",
                "Responsive design with Bootstrap",
                "Flexbox and Grid layout",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Build a personal portfolio webpage",
                "Create a responsive layout",
              ],
            },
            {
              heading: "Resources",
              content: ["MDN Web Docs (HTML & CSS)", "Bootstrap Documentation"],
            },
          ],
        },
        {
          headline: "JavaScript Basics",
          subContent: [
            {
              heading: "Topics",
              content: [
                "JavaScript syntax and data types",
                "DOM manipulation",
                "Event handling",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create interactive web elements",
                "Build a simple to-do list",
              ],
            },
            {
              heading: "Resources",
              content: [
                "MDN Web Docs (JavaScript)",
                "JavaScript.info tutorials",
              ],
            },
          ],
        },
        {
          headline: "Version Control with Git",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Git and GitHub",
                "Basic Git commands",
                "Collaboration workflows",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Initialize a Git repository",
                "Commit and push changes to GitHub",
              ],
            },
            {
              heading: "Resources",
              content: ["Git documentation", "GitHub Guides"],
            },
          ],
        },
        {
          headline: "Basic Web Server",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to HTTP",
                "Setting up a simple server with Node.js",
                "Handling requests and responses",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create a basic Node.js server",
                "Implement routing for different endpoints",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Node.js documentation",
                "Tutorials on setting up servers",
              ],
            },
          ],
        },
        {
          headline: "REST API Creation",
          subContent: [
            {
              heading: "Topics",
              content: [
                "RESTful principles",
                "Creating APIs with Express.js",
                "Handling JSON data",
              ],
            },
            {
              heading: "Activities",
              content: ["Build a simple REST API", "Perform CRUD operations"],
            },
            {
              heading: "Resources",
              content: ["Express.js documentation", "REST API tutorials"],
            },
          ],
        },
        {
          headline: "Database Integration",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to databases (SQL and NoSQL)",
                "Connecting to databases (MySQL, MongoDB)",
                "Performing CRUD operations",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Set up a database",
                "Integrate database with web application",
              ],
            },
            {
              heading: "Resources",
              content: [
                "MySQL and MongoDB documentation",
                "ORM libraries like Sequelize, Mongoose",
              ],
            },
          ],
        },
        {
          headline: "Deployment",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Preparing applications for production",
                "Deploying to platforms like Heroku, AWS",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Deploy a web application",
                "Monitor and maintain the deployment",
              ],
            },
            {
              heading: "Resources",
              content: ["Heroku and AWS documentation", "Deployment tutorials"],
            },
          ],
        },
      ],
      career: [
        "Front-end Developer",
        "Back-end Developer",
        "Full Stack Developer",
        "Web Designer",
      ],
    },
    {
      id: "react",
      name: "React.js Development",
      description:
        "Develop expertise in React.js for building interactive and dynamic user interfaces.",
      duration: "60",
      image: `${constants.imageUrl}react-course.png`,
      cost: "6,499",
      achievements: [
        "React.js fundamentals and component-based architecture",
        "State and props management",
        "Hooks for functional components",
        "Routing with React Router",
        "State management with Redux",
        "Building and consuming APIs",
        "Testing React applications",
        "Deployment of React applications",
      ],
      mainContent: [
        {
          headline: "Introduction to React.js",
          subContent: [
            {
              heading: "Topics",
              content: [
                "React.js fundamentals",
                "Setting up a React project with Create React App",
                "JSX syntax and expressions",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create a new React project",
                "Build a simple React component",
              ],
            },
            {
              heading: "Resources",
              content: ["React documentation", "Create React App tutorial"],
            },
          ],
        },
        {
          headline: "Components and Props",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Creating and composing components",
                "Passing data with props",
                "Component lifecycle methods",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Build reusable components",
                "Manage data flow with props",
              ],
            },
            {
              heading: "Resources",
              content: [
                "React documentation (components and props)",
                "Tutorials on components",
              ],
            },
          ],
        },
        {
          headline: "State and Hooks",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Managing state with useState",
                "Side effects with useEffect",
                "Custom hooks",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Implement stateful components",
                "Use hooks for side effects",
              ],
            },
            {
              heading: "Resources",
              content: ["React documentation (hooks)", "Hooks tutorials"],
            },
          ],
        },
        {
          headline: "Routing with React Router",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Setting up React Router",
                "Creating routes and navigation",
                "Protected routes",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Implement routing in a React app",
                "Secure routes with authentication",
              ],
            },
            {
              heading: "Resources",
              content: ["React Router documentation", "Routing tutorials"],
            },
          ],
        },
        {
          headline: "State Management with Redux",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Redux",
                "Setting up Redux store",
                "Actions, reducers, and selectors",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Manage state with Redux",
                "Connect components to the Redux store",
              ],
            },
            {
              heading: "Resources",
              content: ["Redux documentation", "Tutorials on Redux"],
            },
          ],
        },
        {
          headline: "Testing React Applications",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Unit testing with Jest",
                "Component testing with React Testing Library",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Write unit tests for React components",
                "Perform integration testing",
              ],
            },
            {
              heading: "Resources",
              content: ["React documentation (testing)", "Testing tutorials"],
            },
          ],
        },
        {
          headline: "Deployment",
          subContent: [
            {
              heading: "Topics",
              content: ["Building for production", "Deployment strategies"],
            },
            {
              heading: "Activities",
              content: ["Deploy a React app", "Optimize for performance"],
            },
            {
              heading: "Resources",
              content: [
                "React documentation (deployment)",
                "Deployment guides",
              ],
            },
          ],
        },
      ],
      career: [
        "React.js Developer",
        "Front-end Developer",
        "Full Stack Developer",
      ],
    },
    {
      id: "core-advanced-java",
      name: "Core and Advanced Java",
      description:
        "Gain proficiency in Java programming, covering both core and advanced concepts for building robust applications.",
      duration: "60",
      image: `${constants.imageUrl}core-advanced-java-course.png`,
      cost: "6,499",
      achievements: [
        "Java syntax and OOP principles",
        "Exception handling and file I/O",
        "Collections and generics",
        "Multithreading and concurrency",
        "JDBC for database connectivity",
        "Java 8 features (streams, lambda expressions)",
        "Advanced Java frameworks (Spring, Hibernate)",
        "Web development with Java (Servlets, JSP)",
      ],
      mainContent: [
        {
          headline: "Core Java",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Java syntax and basics",
                "Object-Oriented Programming (OOP) principles",
                "Exception handling",
                "File I/O operations",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Write basic Java programs",
                "Implement OOP concepts in projects",
              ],
            },
            {
              heading: "Resources",
              content: ["Oracle Java documentation", "Core Java tutorials"],
            },
          ],
        },
        {
          headline: "Collections and Generics",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Collection framework",
                "List, Set, and Map interfaces",
                "Generics and type safety",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Use collections in applications",
                "Implement generics for type-safe code",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Java documentation (collections)",
                "Tutorials on collections and generics",
              ],
            },
          ],
        },
        {
          headline: "Multithreading and Concurrency",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Threads and Runnable interface",
                "Synchronization and concurrency utilities",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Write multithreaded applications",
                "Manage concurrency with Java utilities",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Java documentation (multithreading)",
                "Concurrency tutorials",
              ],
            },
          ],
        },
        {
          headline: "JDBC and Database Connectivity",
          subContent: [
            {
              heading: "Topics",
              content: [
                "JDBC architecture",
                "Connecting to databases",
                "Performing CRUD operations",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Set up a database connection",
                "Implement database operations in Java",
              ],
            },
            {
              heading: "Resources",
              content: [
                "JDBC documentation",
                "Database connectivity tutorials",
              ],
            },
          ],
        },
        {
          headline: "Java 8 Features",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Lambda expressions",
                "Stream API",
                "Functional interfaces",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Use lambda expressions and streams",
                "Implement functional programming concepts",
              ],
            },
            {
              heading: "Resources",
              content: ["Java 8 documentation", "Tutorials on Java 8 features"],
            },
          ],
        },
        {
          headline: "Advanced Java Frameworks",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Spring framework",
                "Dependency injection with Spring",
                "ORM with Hibernate",
                "Web development with Spring MVC",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create a Spring Boot application",
                "Implement ORM with Hibernate",
                "Develop web applications with Spring MVC",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Spring documentation",
                "Hibernate documentation",
                "Spring MVC tutorials",
              ],
            },
          ],
        },
        {
          headline: "Web Development with Java",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Servlets",
                "JavaServer Pages (JSP)",
                "Building web applications with Servlets and JSP",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create a web application using Servlets",
                "Develop dynamic web pages with JSP",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Servlets documentation",
                "JSP documentation",
                "Tutorials on Servlets and JSP",
              ],
            },
          ],
        },
      ],
      career: [
        "Java Developer",
        "Software Engineer",
        "Backend Developer",
        "Full Stack Developer",
      ],
    },
    {
      id: "ui-ux",
      name: "UX/UI Designing",
      description:
        "To provide a comprehensive understanding of UX/UI design principles and practical skills necessary for designing interactive and user-friendly digital experiences.",
      duration: "60",
      image: `${constants.imageUrl}ui-ux-course.png`,
      cost: "5,999",
      achievements: [
        "Master fundamental web technologies including HTML, CSS, Bootstrap, and JavaScript.",
        "Gain insights into UX design principles, user research, and persona creation.",
        "Develop proficiency in UI design tools like Figma, including prototyping and usability testing.",
        "Build real-world projects spanning websites, web applications, and mobile apps.",
        "Create a professional design portfolio and prepare for UX/UI design interviews.",
      ],
      mainContent: [
        {
          headline: "Introduction to Web Technologies (20%)",
          subContent: [
            {
              heading: "HTML",
              content: [
                "Basic HTML structure",
                "Common tags and attributes",
                "Creating forms and tables",
              ],
            },
            {
              heading: "CSS",
              content: [
                "CSS syntax and selectors",
                "Styling text and layout",
                "Responsive design basics",
              ],
            },
            {
              heading: "Bootstrap",
              content: [
                "Bootstrap grid system",
                "Common Bootstrap components",
                "Customizing Bootstrap",
              ],
            },
            {
              heading: "JavaScript",
              content: [
                "Basic JavaScript syntax",
                "DOM manipulation",
                "Event handling",
              ],
            },
          ],
        },
        {
          headline: "UX Fundamentals (20%)",
          subContent: [
            {
              heading: "Introduction to UX Design",
              content: [
                "What is UX?",
                "Importance of user-centered design",
                "UX vs. UI",
              ],
            },
            {
              heading: "Wireframing with Whimsical",
              content: [
                "Basics of wireframing",
                "Creating wireframes in Whimsical",
                "Best practices for wireframing",
              ],
            },
            {
              heading: "Mind Mapping and Flow Charts",
              content: [
                "Introduction to mind mapping",
                "Creating flow charts for UX",
                "Tools: Whimsical and FigJam",
              ],
            },
            {
              heading: "User Research and Personas",
              content: [
                "Conducting user research",
                "Creating user personas",
                "Analyzing user data",
              ],
            },
          ],
        },
        {
          headline: "UI Design and Tools (60%)",
          subContent: [
            {
              heading: "Introduction to Figma",
              content: [
                "Figma interface and tools",
                "Creating frames and components",
                "Collaborative design in Figma",
              ],
            },
            {
              heading: "UI Design Principles",
              content: [
                "Principles of good UI design",
                "Color theory and typography",
                "Designing for accessibility",
              ],
            },
            {
              heading: "Advanced Figma Techniques",
              content: [
                "Prototyping interactions in Figma",
                "Using Figma plugins",
                "Design systems and libraries",
              ],
            },
            {
              heading: "Designing User Interfaces",
              content: [
                "Designing common UI elements",
                "Creating design mockups",
                "Iterating on designs based on feedback",
              ],
            },
            {
              heading: "Usability Testing",
              content: [
                "Conducting usability tests",
                "Analyzing test results",
                "Iterating on designs",
              ],
            },
            {
              heading: "Building Real-Time Projects",
              content: [
                "Website (Desktop View)",
                "Website (Mobile View)",
                "Web Application",
                "Mobile App",
              ],
            },
            {
              heading: "Portfolio Development",
              content: [
                "Building a design portfolio",
                "Showcasing your best work",
                "Portfolio review and feedback",
              ],
            },
            {
              heading: "Interview Preparation",
              content: [
                "Preparing for design interviews",
                "Common interview questions",
                "Mock interviews and feedback",
              ],
            },
          ],
        },
      ],
      career: [
        "UX/UI Designer",
        "UI Designer",
        "UX Designer",
        "Visual Designer",
        "Interaction Designer",
        "UX Engineer",
        "Web Designer",
      ],
    },
    {
      id: "vlocityandlwc",
      name: "Salesforce Vlocity plus LWC",
      description:
        "Explore Vlocity, a powerful industry-specific solution on the Salesforce platform, and learn to implement customized solutions.",
      duration: "60",
      image: `${constants.imageUrl}sales-lwc-course.png`,
      cost: "6,499",
      achievements: [
        "Overview of Vlocity and its industry-specific solutions",
        "Key features and benefits of Vlocity",
        "Integration with Salesforce CRM",
        "Use cases and implementation scenarios",
        "Setting up Vlocity data models and templates",
        "Customizing industry-specific solutions with Vlocity cards and omniscripts",
        "Testing and deploying Vlocity solutions",
        "Salesforce Lightning UI enhancements",
        "Introduction to Salesforce Einstein AI features",
        "Mobile app development with Salesforce mobile SDK",
        "Future trends in Salesforce and Vlocity integration",
        "Introduction to LWC and its programming model",
        "Creating custom elements and using Shadow DOM",
        "Comparing LWC with Aura programming model",
        "Integrating LWC with Apex for Salesforce data access",
        "Developing interactive actions and events in LWC",
        "Styling LWC components using CSS and LDS",
        "Deploying LWC components using SFDX CLI",
        "Utilizing LWC in different Salesforce contexts",
      ],
      mainContent: [
        {
          headline: "Introduction to Salesforce Vlocity",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Overview of Vlocity and its industry-specific solutions",
                "Key features and benefits of Vlocity",
                "Integration with Salesforce CRM",
                "Use cases and implementation scenarios",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Explore Vlocity modules and configurations",
                "Discuss real-world Vlocity implementations",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Vlocity documentation and tutorials",
                "Case studies on successful Vlocity deployments",
              ],
            },
          ],
        },
        {
          headline: "Salesforce Vlocity Implementation",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Setting up Vlocity data models and templates",
                "Customizing industry-specific solutions",
                "Configuring Vlocity cards and omniscripts",
                "Testing and deploying Vlocity solutions",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Build sample Vlocity data models and templates",
                "Create omniscripts for specific business processes",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Vlocity developer documentation",
                "Best practices for Vlocity implementation",
              ],
            },
          ],
        },
        {
          headline: "Advanced Topics and Future Trends",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Salesforce Lightning UI enhancements",
                "Introduction to Salesforce Einstein AI features",
                "Mobile app development with Salesforce mobile SDK",
                "Future trends in Salesforce and Vlocity integration",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Experiment with Salesforce Lightning UI customizations",
                "Explore AI features and mobile app development options",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Lightning customization guide",
                "AI integration possibilities in Salesforce",
              ],
            },
          ],
        },
        {
          headline: "LWC Introduction",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Introduction to LWC",
                "Future of LWC/Open Source",
                "ECMA Script 7 Introduction (ES6, ES7)",
                "LWC Programming Model",
                "Custom Elements",
                "Shadow DOM",
                "HTML templates",
                "ES Modules",
                "LWC vs Aura Programming Model",
                "Salesforce LWC History",
                "Aura Coexistence",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Set up a basic LWC project in MS Visual Code.",
                "Explore and experiment with LWC components like custom elements and HTML templates.",
                "Compare and contrast LWC with Aura components to understand their differences.",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Official Salesforce documentation on Lightning Web Components",
                "Trailhead modules on LWC development",
                "Web resources and forums for LWC developers",
              ],
            },
          ],
        },
        {
          headline: "LWC Tool/Infrastructure",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Setting up MS Visual Code",
                "SFDX - CLI",
                "Dev Hub (Create, Authorize, Open, Access, Push, Pull)",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Install and configure MS Visual Code with necessary extensions for LWC development.",
                "Practice using SFDX CLI commands to manage Salesforce orgs and deployments.",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Microsoft Visual Studio Code documentation",
                "Salesforce CLI reference guide",
                "Trailhead modules on SFDX usage",
              ],
            },
          ],
        },
        {
          headline: "SFDX CLI for LWC & Scratch Org",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Installing SFDX CLI",
                "SFDX CLI Commands",
                "Scratch Org - Introduction, Creation, Limit, Configuration",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Practice creating and managing scratch orgs using SFDX commands.",
                "Execute commands like source and source to synchronize code between local and scratch orgs.",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce DX Developer Guide",
                "SFDX CLI Command Reference",
                "Hands-on exercises on Salesforce Trailhead",
              ],
            },
          ],
        },
        {
          headline: "LWC Creation",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Creating Basic LWC (HTML, JS, JS-Meta.xml)",
                "LWC Button Components: Button, Button Group, Button Icon, Button Menu, Button Stateful",
                "LWC Card Components: Card, Layout, Layout-Items Div",
                "LWC Fields: Input, Output",
                "LWC Tab Components: Tab, Tabset, Tabarea",
                "LWC Formatted Components: Date, Number, Phone, Text, Time, Address, Email, URL",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Build and customize basic LWC components like buttons and cards.",
                "Implement different types of input and output fields in LWC components.",
                "Create and style tabs and tabsets using LWC.",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Lightning Web Components documentation",
                "Example projects and code samples for LWC components",
                "Interactive tutorials on building LWC UI elements",
              ],
            },
          ],
        },
        {
          headline: "LWC Actions (Client Actions)",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Creating LWC Actions (On Click, On Change, On Focus, etc.)",
                "@Wire, @Track, @Api",
                "Events Targets",
                "Custom Actions with JavaScript",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Develop interactive actions in LWC components using event handling and decorators like @Wire and @Api.",
                "Implement custom actions and event targets to enhance user interaction in LWC applications.",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce developer documentation on LWC actions and events",
                "Hands-on examples and tutorials on LWC event-driven programming",
              ],
            },
          ],
        },
        {
          headline: "LWC with Apex Class & Methods",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Creating LWC Methods to Access SFDC Data",
                "@AuraEnabled Class Methods",
                "Methods with Parameters",
                "Wire Apex Method to a Function",
                "Importing Apex Methods",
                "Importing Objects and Fields from SFDC Schema",
                "RefreshApex",
                "LWC Apex with Lightning UI and LDS",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Integrate LWC components with Apex classes to access Salesforce data securely.",
                "Use @AuraEnabled methods and wire them to LWC components to retrieve and manipulate Salesforce data.",
                "Explore Lightning Data Service (LDS) and its integration with LWC for seamless UI updates.",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Apex developer guide",
                "Trailhead modules on integrating LWC with Apex",
                "Community forums and best practices for LWC and Apex development",
              ],
            },
          ],
        },
        {
          headline: "LWC with Apex & Styling Components",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Creating a New LWC Component",
                "LWC Component Basic Structure",
                "Using the Salesforce LWC Base Component",
                "Creating Your Own LWC Component",
                "LWC Components for Displaying Lists and Tabs",
                "LWC Components for Navigation and Form Navigation",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Build reusable LWC components for displaying lists, tabs, and navigation elements.",
                "Customize and style LWC components using Salesforce Lightning Design System (LDS) and CSS.",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce LWC component library",
                "Interactive tutorials on creating and styling LWC UI components",
                "Examples of LWC component implementations in Salesforce applications",
              ],
            },
          ],
        },
        {
          headline: "Deployment of LWC on Salesforce",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Using the Lightning Web Component",
                "Deploying LWC on Salesforce",
                "Deployment of LWC with Apex Class & Methods",
                "Integration of LWC with Apex Code and Salesforce",
                "LWC Deployment",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Deploy LWC components to Salesforce orgs using various deployment methods.",
                "Integrate LWC with existing Apex classes and methods for enhanced functionality.",
                "Test and validate LWC deployments across different Salesforce environments.",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce developer guide on deploying Lightning Web Components",
                "Best practices for LWC deployment and version control",
                "Trailhead modules on Salesforce deployment tools and techniques",
              ],
            },
          ],
        },
        {
          headline: "Summary & Review of the LWC",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "LWC Introduction",
                "Future of LWC/Open Source",
                "ECMA Script 7 Introduction (ES6, ES7)",
                "LWC Programming Model",
                "Custom Elements",
                "Shadow DOM",
                "HTML templates",
                "ES Modules",
                "LWC vs Aura Programming Model",
                "Salesforce LWC History",
                "Aura Coexistence",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Set up a basic LWC project in MS Visual Code.",
                "Explore and experiment with LWC components like custom elements and HTML templates.",
                "Compare and contrast LWC with Aura components to understand their differences.",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Official Salesforce documentation on Lightning Web Components",
                "Trailhead modules on LWC development",
                "Web resources and forums for LWC developers",
              ],
            },
          ],
        },
      ],
      career: [
        "Salesforce Vlocity developer",
        "Salesforce LWC developer",
        "Salesforce CRM consultant",
        "Salesforce application architect",
        "Salesforce solution designer",
        "Salesforce technical lead",
        "Salesforce integration specialist",
        "Salesforce platform developer",
        "Salesforce administrator",
        "Salesforce consultant",
        "Salesforce project manager",
      ],
    },
    {
      id: "sales",
      name: "Salesforce Admin plus Development plus LWC",
      description:
        "Learn the fundamentals of Salesforce administration and development, including cloud computing concepts, CRM processes, automation, and coding with Apex.",
      duration: "90",
      image: `${constants.imageUrl}sales-full-stack-course.png`,
      cost: "12,999",
      achievements: [
        "Fundamentals of cloud computing and CRM",
        "Setting up and navigating Salesforce",
        "Managing Salesforce objects and data",
        "Implementing process automations and approval processes",
        "Creating and managing reports and dashboards",
        "Securing data and managing user roles",
        "Developing with Apex and creating custom triggers",
        "Implementing web services and integrations",
        "Deploying Salesforce solutions",
      ],
      mainContent: [
        {
          headline: "Introduction to Salesforce Admin",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Cloud Computing Concepts",
                "Introduction",
                "Evolution of Cloud Computing",
                "Comparison with other computing techniques",
                "Advantages and Disadvantages",
                "Classification of Cloud Computing Services (PAAS, SAAS, IAAS)",
                "Salesforce CRM Concepts",
                "CRM Processes (Marketing, Sales, Support)",
                "Lead Conversion Process",
                "Salesforce Server Instances & Releases",
                "Multitenant Architecture",
                "Salesforce Editions & Pricing",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Explore different cloud computing services",
                "Understand CRM processes through case studies",
              ],
            },
            {
              heading: "Resources",
              content: ["Salesforce Trailhead", "Cloud Computing tutorials"],
            },
          ],
        },
        {
          headline: "Creating Salesforce Developer Account",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Overview of Salesforce Products",
                "Creating Salesforce Developer Account",
                "Salesforce CRM Account Overview (Sales, Service, Marketing)",
                "AppExchange",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Set up a Salesforce Developer Account",
                "Navigate through Salesforce CRM",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Developer Documentation",
                "AppExchange Guide",
              ],
            },
          ],
        },
        {
          headline: "Salesforce Object Management",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Standard Objects and Custom Objects",
                "Creating, Editing, and Deleting Records",
                "Creating Custom Fields and Custom Objects",
                "Field Dependency and Field History Tracking",
                "Lead Conversion Process",
                "Web to Lead",
                "Creating Applications & Configuration",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create and customize objects and fields",
                "Implement a lead conversion process",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Object Management Guide",
                "Tutorials on Custom Fields",
              ],
            },
          ],
        },
        {
          headline: "Data Validation & Formula Fields",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Data Validation Rules & Formula Fields",
                "Salesforce Functions for Validation Rules & Formula Fields",
                "Custom Formulas & Cross-Object Formulas",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create data validation rules and formula fields",
                "Implement custom formulas",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Validation Rules Documentation",
                "Formula Field Examples",
              ],
            },
          ],
        },
        {
          headline: "Customizing Page Layouts",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Creating and Customizing Page Layouts",
                "Page Layout Assignments",
                "Creating Salesforce Business Records",
                "Introduction to Business Processes",
                "Customizing Record Types",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Customize page layouts and record types",
                "Implement business processes",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Page Layouts Documentation",
                "Tutorials on Record Types",
              ],
            },
          ],
        },
        {
          headline: "Object Relationships",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Relationships in Salesforce",
                "Lookup and External Lookup Relationships",
                "Master-Detail Relationship",
                "Configuring Lookup Dialogs and Lookup Filters",
                "Many-to-Many Relationships using Junction Objects",
                "Self-Relationship / Hierarchical Relationship",
                "Rollup Summary Fields",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create and configure different types of relationships",
                "Implement rollup summary fields",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Object Relationships Guide",
                "Tutorials on Junction Objects",
              ],
            },
          ],
        },
        {
          headline: "Process Automations using Automation Rules",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Workflow Rules, Process Builder, and Lightning Flows",
                "Types of Workflows and Actions",
                "Automate Actions with Process Builder",
                "Overview of Flow Elements",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Set up workflow rules and process builders",
                "Create screen flows and auto-launched flows",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Automation Rules Documentation",
                "Tutorials on Lightning Flows",
              ],
            },
          ],
        },
        {
          headline: "Approval Process",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Overview of Approval Process",
                "Creating Approval Processes and Multi-Step Approvals",
                "Submit Records for Auto-Approval",
              ],
            },
            {
              heading: "Activities",
              content: ["Create and implement approval processes"],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Approval Process Documentation",
                "Tutorials on Multi-Step Approvals",
              ],
            },
          ],
        },
        {
          headline: "Data Management / Data Migration",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Data Loader",
                "Import & Export Operations",
                "Data Deduplication with Duplicate Management",
                "Data Backups and Restores",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Perform data migration using Data Loader",
                "Implement data deduplication",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Data Management Guide",
                "Data Loader Documentation",
              ],
            },
          ],
        },
        {
          headline: "Analytics in Salesforce",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Creating Reports and Report Types",
                "Types of Reports",
                "Custom Report Types & Implementation",
                "Creating Dashboards and Components",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create and schedule reports",
                "Implement dynamic dashboards",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Analytics Guide",
                "Tutorials on Creating Reports",
              ],
            },
          ],
        },
        {
          headline: "Security & Data Visibility",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Security Overview and Licenses",
                "Profiles, Roles, and User Management",
                "OWD (Organization-Wide Defaults)",
                "Sharing Settings and Automated Sharing",
                "Record Ownership Transfer",
                "Queues and Assignment Rules",
                "Configuring Web to Lead/Case and Email to Case",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Set up security measures and user roles",
                "Implement sharing settings and queues",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Security Guide",
                "Tutorials on Sharing Settings",
              ],
            },
          ],
        },
        {
          headline: "Service Cloud Implementation",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Service Cloud and Objects",
                "Case Assignment Rules",
                "Web to Case & Email to Case",
                "Entitlement Management & Service Console",
              ],
            },
            {
              heading: "Activities",
              content: ["Implement case management and assignment rules"],
            },
            {
              heading: "Resources",
              content: [
                "Service Cloud Implementation Guide",
                "Tutorials on Case Management",
              ],
            },
          ],
        },
        {
          headline: "Sales Cloud Implementation",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Sales Cloud and Objects",
                "Lead Management, Accounts, Contacts, and Opportunities",
                "Managing Campaigns",
                "Sales Productivity Features (Big Deals, Sales Path)",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Implement lead and opportunity management",
                "Manage campaigns and sales paths",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Sales Cloud Implementation Guide",
                "Tutorials on Lead Management",
              ],
            },
          ],
        },
        {
          headline: "Apex Programming Basics",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Apex",
                "Apex Data Types, Collections, and Variables",
                "Control Flow Statements",
                "Creating Classes, Methods, and Triggers",
              ],
            },
            {
              heading: "Activities",
              content: ["Write basic Apex classes and triggers"],
            },
            {
              heading: "Resources",
              content: [
                "Apex Programming Guide",
                "Tutorials on Writing Triggers",
              ],
            },
          ],
        },
        {
          headline: "Advanced Apex Development",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Exception Handling in Apex",
                "Bulk Processing and Batch Apex",
                "Apex Scheduler",
                "Apex REST and SOAP Web Services",
                "Implementing Custom Controllers and Extensions",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Implement bulk processing and batch apex",
                "Create custom REST web services",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Advanced Apex Programming Guide",
                "Tutorials on Apex Web Services",
              ],
            },
          ],
        },
        {
          headline: "Visualforce Page Development",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Visualforce",
                "Creating Visualforce Pages and Controllers",
                "Standard and Custom Controllers",
                "Working with Visualforce Components",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create and customize Visualforce pages",
                "Implement standard and custom controllers",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Visualforce Development Guide",
                "Tutorials on Visualforce Components",
              ],
            },
          ],
        },
        {
          headline: "Lightning Component Development",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Lightning Components",
                "Aura Framework Basics",
                "Building Lightning Components",
                "Implementing Lightning Apps",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Develop basic Lightning components",
                "Create a Lightning app",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Lightning Component Development Guide",
                "Aura Framework Documentation",
              ],
            },
          ],
        },
        {
          headline: "Deploying Salesforce Solutions",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Change Sets and Deployment Tools",
                "Salesforce DX and CLI",
                "Continuous Integration and Delivery in Salesforce",
                "Managing Sandboxes and Environments",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Set up and deploy change sets",
                "Implement CI/CD with Salesforce DX",
              ],
            },
            {
              heading: "Resources",
              content: ["Salesforce DX Guide", "Tutorials on Change Sets"],
            },
          ],
        },
        {
          headline: "LWC Introduction",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Introduction to LWC",
                "Future of LWC/Open Source",
                "ECMA Script 7 Introduction (ES6, ES7)",
                "LWC Programming Model",
                "Custom Elements",
                "Shadow DOM",
                "HTML templates",
                "ES Modules",
                "LWC vs Aura Programming Model",
                "Salesforce LWC History",
                "Aura Coexistence",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Set up a basic LWC project in MS Visual Code.",
                "Explore and experiment with LWC components like custom elements and HTML templates.",
                "Compare and contrast LWC with Aura components to understand their differences.",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Official Salesforce documentation on Lightning Web Components",
                "Trailhead modules on LWC development",
                "Web resources and forums for LWC developers",
              ],
            },
          ],
        },
        {
          headline: "LWC Tool/Infrastructure",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Setting up MS Visual Code",
                "SFDX - CLI",
                "Dev Hub (Create, Authorize, Open, Access, Push, Pull)",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Install and configure MS Visual Code with necessary extensions for LWC development.",
                "Practice using SFDX CLI commands to manage Salesforce orgs and deployments.",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Microsoft Visual Studio Code documentation",
                "Salesforce CLI reference guide",
                "Trailhead modules on SFDX usage",
              ],
            },
          ],
        },
        {
          headline: "SFDX CLI for LWC & Scratch Org",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Installing SFDX CLI",
                "SFDX CLI Commands",
                "Scratch Org - Introduction, Creation, Limit, Configuration",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Practice creating and managing scratch orgs using SFDX commands.",
                "Execute commands like source and source to synchronize code between local and scratch orgs.",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce DX Developer Guide",
                "SFDX CLI Command Reference",
                "Hands-on exercises on Salesforce Trailhead",
              ],
            },
          ],
        },
        {
          headline: "LWC Creation",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Creating Basic LWC (HTML, JS, JS-Meta.xml)",
                "LWC Button Components: Button, Button Group, Button Icon, Button Menu, Button Stateful",
                "LWC Card Components: Card, Layout, Layout-Items Div",
                "LWC Fields: Input, Output",
                "LWC Tab Components: Tab, Tabset, Tabarea",
                "LWC Formatted Components: Date, Number, Phone, Text, Time, Address, Email, URL",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Build and customize basic LWC components like buttons and cards.",
                "Implement different types of input and output fields in LWC components.",
                "Create and style tabs and tabsets using LWC.",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Lightning Web Components documentation",
                "Example projects and code samples for LWC components",
                "Interactive tutorials on building LWC UI elements",
              ],
            },
          ],
        },
        {
          headline: "LWC Actions (Client Actions)",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Creating LWC Actions (On Click, On Change, On Focus, etc.)",
                "@Wire, @Track, @Api",
                "Events Targets",
                "Custom Actions with JavaScript",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Develop interactive actions in LWC components using event handling and decorators like @Wire and @Api.",
                "Implement custom actions and event targets to enhance user interaction in LWC applications.",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce developer documentation on LWC actions and events",
                "Hands-on examples and tutorials on LWC event-driven programming",
              ],
            },
          ],
        },
        {
          headline: "LWC with Apex Class & Methods",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Creating LWC Methods to Access SFDC Data",
                "@AuraEnabled Class Methods",
                "Methods with Parameters",
                "Wire Apex Method to a Function",
                "Importing Apex Methods",
                "Importing Objects and Fields from SFDC Schema",
                "RefreshApex",
                "Continuation",
                "Continuation Limits",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Integrate Apex classes and methods with LWC components to access Salesforce data.",
                "Practice using @AuraEnabled methods and handling parameters in LWC applications.",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Apex Developer Guide",
                "LWC integration with Apex examples and best practices",
                "Hands-on exercises on Apex and LWC integration",
              ],
            },
          ],
        },
        {
          headline: "LWC Events",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Introduction to LWC Events",
                "Creating and Dispatching Events",
                "Passing Data in LWC Events",
                "Event Handlers",
                "Event Propagation (Bubbles and Composed)",
                "addEventListener",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Develop and manage events in LWC to facilitate communication between components.",
                "Implement event propagation strategies like bubbling and composed events in LWC applications.",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce documentation on LWC event handling",
                "Event-driven architecture in Lightning Web Components",
                "Practical examples and use cases for LWC events",
              ],
            },
          ],
        },
        {
          headline: "LWC Data",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Introduction to LWC Data System",
                "LWCDS Load, Edit, Create",
                "LWC Data Tables",
                "LWC Data with @Wire",
                "LWC Data Error Handling",
                "General LWC Data Concepts",
                "LWC Data with SObject",
                "LWC Data without Parameters",
                "LWC Data with Parameters",
                "LWC DS with Server-Side Controllers",
                "LWC DS with Client-Side Controllers",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Implement data management functionalities in LWC using LWC Data Services (LWCDS).",
                "Practice loading, editing, and creating data records with and without parameters.",
                "Handle data errors and exceptions effectively in LWC applications.",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce LWC Data Services documentation",
                "Practical examples and tutorials on using LWCDS for data operations",
                "Hands-on exercises on LWC data management",
              ],
            },
          ],
        },
        {
          headline: "LWC Styles (CSS)",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Introduction to LWC Styles",
                "Defining LWC Custom Styles",
                "Defining LDS (Lightning Design System) Styles",
                "Creating Custom CSS Components",
                "Calling Custom CSS Components in LWC",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create and apply custom styles to LWC components using CSS.",
                "Integrate Lightning Design System (LDS) styles into LWC applications for consistent UI design.",
                "Develop reusable CSS components and apply them across LWC projects.",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Lightning Design System documentation",
                "CSS best practices for LWC development",
                "Examples and templates for LWC styling",
              ],
            },
          ],
        },
        {
          headline: "LWC Deployment",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "LWC Push/Deployment to Org",
                "LWC Targets",
                "LWC Usage in App, Tab, Record, Home",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Deploy LWC components to Salesforce orgs using SFDX commands.",
                "Configure and utilize LWC components in different Salesforce contexts such as apps, tabs, records, and home pages.",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce deployment strategies for LWC components",
                "Best practices for deploying and managing LWC applications",
                "Hands-on exercises on LWC deployment and usage scenarios",
              ],
            },
          ],
        },
      ],
      career: [
        "Salesforce Vlocity developer",
        "Salesforce LWC developer",
        "Salesforce CRM consultant",
        "Salesforce application architect",
        "Salesforce solution designer",
        "Salesforce technical lead",
        "Salesforce integration specialist",
        "Salesforce platform developer",
        "Salesforce administrator",
        "Salesforce consultant",
        "Salesforce project manager",
      ],
    },
    {
      id: "salesforce-admin-vlocity",
      name: "Salesforce Admin plus Vlocity",
      description:
        "Master Salesforce Administration and Vlocity for effective CRM customization and industry-specific solutions.",
      duration: "60",
      image: `${constants.imageUrl}sales-admin-velocity-course.png`,
      cost: "6,499",
      achievements: [
        "Salesforce architecture and CRM concepts",
        "Salesforce Developer Account setup and usage",
        "Salesforce Object Management and customization",
        "Data validation, formula fields, and page layouts",
        "Object relationships and email template creation",
        "Process automations using Workflow Rules, Process Builder, and Lightning Flows",
        "Approval processes and data management techniques",
        "Analytics with reports and dashboards in Salesforce",
        "Security settings, user management, and data visibility",
        "Implementation of Service Cloud and Sales Cloud features",
        "Introduction to Salesforce Vlocity and its modules",
      ],
      mainContent: [
        {
          headline: "Cloud Computing Concepts",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Introduction to cloud computing",
                "Evolution and comparison with other computing techniques",
                "Advantages, disadvantages, and classification of cloud computing services (PAAS, SAAS, IAAS)",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Research and compare different cloud computing models",
                "Discuss advantages and disadvantages in a group setting",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Online articles on cloud computing models",
                "Whitepapers from major cloud service providers",
              ],
            },
          ],
        },
        {
          headline: "Salesforce CRM Concepts",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "CRM processes: Marketing, Sales, Support",
                "Lead conversion process",
                "Salesforce server instances and releases",
                "Multitenant architecture",
                "Salesforce editions and pricing",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create a sample CRM process flowchart",
                "Practice lead conversion in a Salesforce Developer Account",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Trailhead modules on CRM processes",
                "Salesforce documentation on server instances",
              ],
            },
          ],
        },
        {
          headline: "Creating Salesforce Developer Account",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Overview of Salesforce products",
                "Creating a Salesforce Developer Account",
                "Salesforce CRM account overview (Sales, Service, Marketing)",
                "Introduction to AppExchange",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Set up a Salesforce Developer Account with specific configurations",
                "Explore available apps on the AppExchange platform",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Developer Edition setup guide",
                "AppExchange documentation and tutorials",
              ],
            },
          ],
        },
        {
          headline: "Salesforce Object Management",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Standard and custom objects",
                "Records management (create, edit, delete)",
                "Custom fields and objects, field dependency, history tracking",
                "Lead conversion process, web to lead",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create custom objects and fields in Salesforce",
                "Perform data imports and exports using Data Loader",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Object Management documentation",
                "Data Loader user guide",
              ],
            },
          ],
        },
        {
          headline: "Data Validation and Formula Fields",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Salesforce CRM data validation",
                "Creating data validation rules and formula fields",
                "Salesforce functions for validation rules and formula fields",
                "Customizing page layouts",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Set up data validation rules for specific scenarios",
                "Create formula fields to automate data calculations",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce validation rules documentation",
                "Formula fields reference guide",
              ],
            },
          ],
        },
        {
          headline: "Object Relationships",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Relationships in Salesforce (Lookup, External Lookup, Master-Detail, Many-to-Many)",
                "Configuring lookup dialogs and filters",
                "Self-relationship, hierarchical relationship",
                "Creating rollup summary fields, converting relationships",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Build a sample database schema with various relationship types",
                "Configure rollup summary fields to aggregate data",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce relationships and schema builder guide",
                "Online tutorials on Salesforce relationship management",
              ],
            },
          ],
        },
        {
          headline: "Creating Email Templates",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Overview of email templates",
                "Types of email templates (Plain Text, HTML, Custom Type)",
                "Creating and using email templates",
                "Including record data with merge fields",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Design and create email templates for different business scenarios",
                "Test email templates with sample Salesforce data",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce email templates documentation",
                "Email marketing best practices guides",
              ],
            },
          ],
        },
        {
          headline: "Process Automations using Automation Rules",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Workflow Rules: Define, setup, types, tasks (Email Alerts, Field Updates, Outbound Messages)",
                "Process Builder: Overview, differences, supported actions, automation",
                "Lightning Flows: Overview, types, elements, creating and using flows",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create workflow rules to automate routine tasks",
                "Build a process in Process Builder to streamline business processes",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce workflow automation guide",
                "Lightning Flow developer documentation",
              ],
            },
          ],
        },
        {
          headline: "Approval Process",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Overview and creation of approval processes",
                "Multi-step approvals, auto-approval settings",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Design and implement approval processes for different approval scenarios",
                "Test approval process functionality in a sandbox environment",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce approval process documentation",
                "Use case examples for approval processes",
              ],
            },
          ],
        },
        {
          headline: "Data Management and Migration",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Introduction to Data Loader, import/export operations",
                "Performing DML operations, import wizard, MuleSoft.IO, Workbench",
                "Data deduplication, backups, restores",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Practice data imports and exports using Data Loader",
                "Perform data deduplication and backup operations",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Data Loader user guide",
                "Data migration best practices documentation",
              ],
            },
          ],
        },
        {
          headline: "Analytics in Salesforce",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Creating reports (standard, custom report types)",
                "Types of reports (Tabular, Matrix, Summary, Joined)",
                "Creating custom report types, scheduling reports",
                "Introduction to dashboards, components, charts",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create sample reports based on different datasets",
                "Design interactive dashboards for management review",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce reports and dashboards documentation",
                "Best practices for report and dashboard design",
              ],
            },
          ],
        },
        {
          headline: "Security and Data Visibility",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Security overview, license types, profiles, user management",
                "Role hierarchy setup, OWD settings, record-level security",
                "Field-level security, permission sets, sharing rules",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Configure security settings for different user roles",
                "Implement record-level security measures",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce security implementation guide",
                "User permission and access management tutorials",
              ],
            },
          ],
        },
        {
          headline: "Introduction to Salesforce Vlocity",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Overview of Vlocity and its industry-specific solutions",
                "Key features and benefits of Vlocity",
                "Integration with Salesforce CRM",
                "Use cases and implementation scenarios",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Explore Vlocity modules and configurations",
                "Discuss real-world Vlocity implementations",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Vlocity documentation and tutorials",
                "Case studies on successful Vlocity deployments",
              ],
            },
          ],
        },
        {
          headline: "Salesforce Vlocity Implementation",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Setting up Vlocity data models and templates",
                "Customizing industry-specific solutions",
                "Configuring Vlocity cards and omniscripts",
                "Testing and deploying Vlocity solutions",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Build sample Vlocity data models and templates",
                "Create omniscripts for specific business processes",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Vlocity developer documentation",
                "Best practices for Vlocity implementation",
              ],
            },
          ],
        },
        {
          headline: "Advanced Topics and Future Trends",
          subContent: [
            {
              heading: "Topics Covered",
              content: [
                "Salesforce Lightning UI enhancements",
                "Introduction to Salesforce Einstein AI features",
                "Mobile app development with Salesforce mobile SDK",
                "Future trends in Salesforce and Vlocity integration",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Experiment with Salesforce Lightning UI customizations",
                "Explore AI features and mobile app development options",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Lightning customization guide",
                "AI integration possibilities in Salesforce",
              ],
            },
          ],
        },
      ],
      career: [
        "Salesforce Administrator",
        "Salesforce Developer",
        "Salesforce Vlocity Developer",
        "Salesforce Consultant",
      ],
    },
    {
      id: "sales-admin-development",
      name: "Salesforce Admin + Development",
      description:
        "Learn the fundamentals of Salesforce administration and development, including cloud computing concepts, CRM processes, automation, and coding with Apex.",
      duration: "60",
      image: `${constants.imageUrl}node_course.png`,
      cost: "6,499",
      achievements: [
        "Fundamentals of cloud computing and CRM",
        "Setting up and navigating Salesforce",
        "Managing Salesforce objects and data",
        "Implementing process automations and approval processes",
        "Creating and managing reports and dashboards",
        "Securing data and managing user roles",
        "Developing with Apex and creating custom triggers",
        "Implementing web services and integrations",
        "Deploying Salesforce solutions",
      ],
      mainContent: [
        {
          headline: "Introduction to Salesforce Admin",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Cloud Computing Concepts",
                "Introduction",
                "Evolution of Cloud Computing",
                "Comparison with other computing techniques",
                "Advantages and Disadvantages",
                "Classification of Cloud Computing Services (PAAS, SAAS, IAAS)",
                "Salesforce CRM Concepts",
                "CRM Processes (Marketing, Sales, Support)",
                "Lead Conversion Process",
                "Salesforce Server Instances & Releases",
                "Multitenant Architecture",
                "Salesforce Editions & Pricing",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Explore different cloud computing services",
                "Understand CRM processes through case studies",
              ],
            },
            {
              heading: "Resources",
              content: ["Salesforce Trailhead", "Cloud Computing tutorials"],
            },
          ],
        },
        {
          headline: "Creating Salesforce Developer Account",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Overview of Salesforce Products",
                "Creating Salesforce Developer Account",
                "Salesforce CRM Account Overview (Sales, Service, Marketing)",
                "AppExchange",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Set up a Salesforce Developer Account",
                "Navigate through Salesforce CRM",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Developer Documentation",
                "AppExchange Guide",
              ],
            },
          ],
        },
        {
          headline: "Salesforce Object Management",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Standard Objects and Custom Objects",
                "Creating, Editing, and Deleting Records",
                "Creating Custom Fields and Custom Objects",
                "Field Dependency and Field History Tracking",
                "Lead Conversion Process",
                "Web to Lead",
                "Creating Applications & Configuration",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create and customize objects and fields",
                "Implement a lead conversion process",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Object Management Guide",
                "Tutorials on Custom Fields",
              ],
            },
          ],
        },
        {
          headline: "Data Validation & Formula Fields",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Data Validation Rules & Formula Fields",
                "Salesforce Functions for Validation Rules & Formula Fields",
                "Custom Formulas & Cross-Object Formulas",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create data validation rules and formula fields",
                "Implement custom formulas",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Validation Rules Documentation",
                "Formula Field Examples",
              ],
            },
          ],
        },
        {
          headline: "Customizing Page Layouts",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Creating and Customizing Page Layouts",
                "Page Layout Assignments",
                "Creating Salesforce Business Records",
                "Introduction to Business Processes",
                "Customizing Record Types",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Customize page layouts and record types",
                "Implement business processes",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Page Layouts Documentation",
                "Tutorials on Record Types",
              ],
            },
          ],
        },
        {
          headline: "Object Relationships",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Relationships in Salesforce",
                "Lookup and External Lookup Relationships",
                "Master-Detail Relationship",
                "Configuring Lookup Dialogs and Lookup Filters",
                "Many-to-Many Relationships using Junction Objects",
                "Self-Relationship / Hierarchical Relationship",
                "Rollup Summary Fields",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create and configure different types of relationships",
                "Implement rollup summary fields",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Object Relationships Guide",
                "Tutorials on Junction Objects",
              ],
            },
          ],
        },
        {
          headline: "Process Automations using Automation Rules",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Workflow Rules, Process Builder, and Lightning Flows",
                "Types of Workflows and Actions",
                "Automate Actions with Process Builder",
                "Overview of Flow Elements",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Set up workflow rules and process builders",
                "Create screen flows and auto-launched flows",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Automation Rules Documentation",
                "Tutorials on Lightning Flows",
              ],
            },
          ],
        },
        {
          headline: "Approval Process",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Overview of Approval Process",
                "Creating Approval Processes and Multi-Step Approvals",
                "Submit Records for Auto-Approval",
              ],
            },
            {
              heading: "Activities",
              content: ["Create and implement approval processes"],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Approval Process Documentation",
                "Tutorials on Multi-Step Approvals",
              ],
            },
          ],
        },
        {
          headline: "Data Management / Data Migration",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Data Loader",
                "Import & Export Operations",
                "Data Deduplication with Duplicate Management",
                "Data Backups and Restores",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Perform data migration using Data Loader",
                "Implement data deduplication",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Data Management Guide",
                "Data Loader Documentation",
              ],
            },
          ],
        },
        {
          headline: "Analytics in Salesforce",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Creating Reports and Report Types",
                "Types of Reports",
                "Custom Report Types & Implementation",
                "Creating Dashboards and Components",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create and schedule reports",
                "Implement dynamic dashboards",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Analytics Guide",
                "Tutorials on Creating Reports",
              ],
            },
          ],
        },
        {
          headline: "Security & Data Visibility",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Security Overview and Licenses",
                "Profiles, Roles, and User Management",
                "OWD (Organization-Wide Defaults)",
                "Sharing Settings and Automated Sharing",
                "Record Ownership Transfer",
                "Queues and Assignment Rules",
                "Configuring Web to Lead/Case and Email to Case",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Set up security measures and user roles",
                "Implement sharing settings and queues",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Salesforce Security Guide",
                "Tutorials on Sharing Settings",
              ],
            },
          ],
        },
        {
          headline: "Service Cloud Implementation",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Service Cloud and Objects",
                "Case Assignment Rules",
                "Web to Case & Email to Case",
                "Entitlement Management & Service Console",
              ],
            },
            {
              heading: "Activities",
              content: ["Implement case management and assignment rules"],
            },
            {
              heading: "Resources",
              content: [
                "Service Cloud Implementation Guide",
                "Tutorials on Case Management",
              ],
            },
          ],
        },
        {
          headline: "Sales Cloud Implementation",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Sales Cloud and Objects",
                "Lead Management, Accounts, Contacts, and Opportunities",
                "Managing Campaigns",
                "Sales Productivity Features (Big Deals, Sales Path)",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Implement lead and opportunity management",
                "Manage campaigns and sales paths",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Sales Cloud Implementation Guide",
                "Tutorials on Lead Management",
              ],
            },
          ],
        },
        {
          headline: "Apex Programming Basics",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Apex",
                "Apex Data Types, Collections, and Variables",
                "Control Flow Statements",
                "Creating Classes, Methods, and Triggers",
              ],
            },
            {
              heading: "Activities",
              content: ["Write basic Apex classes and triggers"],
            },
            {
              heading: "Resources",
              content: [
                "Apex Programming Guide",
                "Tutorials on Writing Triggers",
              ],
            },
          ],
        },
        {
          headline: "Advanced Apex Development",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Exception Handling in Apex",
                "Bulk Processing and Batch Apex",
                "Apex Scheduler",
                "Apex REST and SOAP Web Services",
                "Implementing Custom Controllers and Extensions",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Implement bulk processing and batch apex",
                "Create custom REST web services",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Advanced Apex Programming Guide",
                "Tutorials on Apex Web Services",
              ],
            },
          ],
        },
        {
          headline: "Visualforce Page Development",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Visualforce",
                "Creating Visualforce Pages and Controllers",
                "Standard and Custom Controllers",
                "Working with Visualforce Components",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Create and customize Visualforce pages",
                "Implement standard and custom controllers",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Visualforce Development Guide",
                "Tutorials on Visualforce Components",
              ],
            },
          ],
        },
        {
          headline: "Lightning Component Development",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Introduction to Lightning Components",
                "Aura Framework Basics",
                "Building Lightning Components",
                "Implementing Lightning Apps",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Develop basic Lightning components",
                "Create a Lightning app",
              ],
            },
            {
              heading: "Resources",
              content: [
                "Lightning Component Development Guide",
                "Aura Framework Documentation",
              ],
            },
          ],
        },
        {
          headline: "Deploying Salesforce Solutions",
          subContent: [
            {
              heading: "Topics",
              content: [
                "Change Sets and Deployment Tools",
                "Salesforce DX and CLI",
                "Continuous Integration and Delivery in Salesforce",
                "Managing Sandboxes and Environments",
              ],
            },
            {
              heading: "Activities",
              content: [
                "Set up and deploy change sets",
                "Implement CI/CD with Salesforce DX",
              ],
            },
            {
              heading: "Resources",
              content: ["Salesforce DX Guide", "Tutorials on Change Sets"],
            },
          ],
        },
      ],
      career: [
        "Salesforce Vlocity developer",
        "Salesforce LWC developer",
        "Salesforce CRM consultant",
        "Salesforce application architect",
        "Salesforce solution designer",
        "Salesforce technical lead",
        "Salesforce integration specialist",
        "Salesforce platform developer",
        "Salesforce administrator",
        "Salesforce consultant",
        "Salesforce project manager",
      ],
    },
  ],
};

export default constants;
